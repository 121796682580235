import React, { useState } from 'react';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { iTemplateScreen } from '../../Templates/repository/TemplateRepo';
import { TemplateStatusCard } from '../Components/TemplateStatusCard';
import ContractSelectClients from '../Components/ContractSelectClients';
import { styled } from 'styled-components';
import { ActionCard } from '../Components/ActionCard';
import { saveContractInClients } from '../repository/SettingsRepo';

interface props {
    onClose: () => void;
    onSaved: () => void;
}

interface iAction {
    ids: number[];
    templateId: number;
}

const CreateContractForm: React.FC<props> = ({ onClose, onSaved }) => {

    const { addNotification } = useNotifications();
    const [selectedTemplate, setSelectedTemplate] = useState<iTemplateScreen>();
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());



    const handleSave = async () => {
        try {
            if (!selectedTemplate) {
                throw new Error('Selecione um template para criar o contrato');
            }
            if (selectedRows.size === 0) {
                throw new Error('Selecione ao menos um cliente para criar o contrato');
            }
            let paylodad: iAction = {
                ids: Array.from(selectedRows),
                templateId: selectedTemplate.id
            }
            await saveContractInClients(paylodad)
            addNotification('', 'Contrato vinculado com sucesso', 'success');
            onSaved();
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }



    return (
        <Container>
            {/* Coluna da esquerda */}
            <Column>
                <ActionCard
                    onSave={handleSave}
                    onCancel={onClose}
                    isSelectedClients={selectedRows.size > 0}
                    isSelectedTemplate={!!selectedTemplate}
                />
                <TemplateStatusCard
                    isTemplateSelected={!!selectedTemplate}
                    selectedTemplate={selectedTemplate}
                    onSelectTemplate={setSelectedTemplate}
                />
            </Column>

            {/* Coluna da direita */}
            <Column>
                <ContractSelectClients
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            </Column>
        </Container>
    )

}
export default CreateContractForm


const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr; /* Duas colunas com largura igual */
    gap: 16px; /* Espaçamento entre as colunas */
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: #f5f5f5;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Uma coluna em telas menores */
        gap: 8px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
