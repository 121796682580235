import React, { useState } from 'react';
import styled from 'styled-components';
import { iTemplateScreen } from '../../Templates/repository/TemplateRepo';
import { FaExclamationCircle, FaCheckCircle, FaExchangeAlt, FaInfoCircle, FaFileContract, FaCalendarAlt } from 'react-icons/fa';
import ModalListTemplates from '../../Templates/modal/ModalListTemplates';

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  max-width: 28rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
`;

const StatusContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => props.$isSelected ? '#059669' : '#d97706'};
`;

const Description = styled.p`
  margin-top: 1rem;
  padding: 1rem;
  background: #f3f4f6;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #4b5563;
  line-height: 1.5;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  background: #64748b;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background: #475569;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: #e5e7eb;
  margin: 1rem 0;
`;

const ChangeTemplateButton = styled.button`
  background: transparent;
  color: #64748b;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;

  &:hover {
    background: #f3f4f6;
  }
`;

const TemplateDetails = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.5rem;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  color: #475569;
  font-size: 0.875rem;

  svg {
    color: #64748b;
  }
`;

interface TemplateStatusCardProps {
  isTemplateSelected: boolean;
  selectedTemplate?: iTemplateScreen;
  onSelectTemplate: (template: iTemplateScreen) => void;
}

export function TemplateStatusCard({
  isTemplateSelected,
  selectedTemplate,
  onSelectTemplate
}: TemplateStatusCardProps) {
  const [isOpenListTemplates, setIsOpenListTemplates] = useState(false);

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <>
      <Card>
        <Header>
          <div>
            <Title>Contrato</Title>
            <StatusContainer $isSelected={isTemplateSelected}>
              {isTemplateSelected ? (
                <>
                  <FaCheckCircle className="w-5 h-5 mr-2" />
                  <p className="text-sm">Contrato selecionado com sucesso!</p>
                </>
              ) : (
                <>
                  <FaExclamationCircle className="w-5 h-5 mr-2" />
                  <p className="text-sm">Por favor, selecione um contrato para continuar</p>
                </>
              )}
            </StatusContainer>
          </div>
          {isTemplateSelected && (
            <ChangeTemplateButton onClick={() => setIsOpenListTemplates(true)}>
              <FaExchangeAlt size={20} />
            </ChangeTemplateButton>
          )}
        </Header>

        {isTemplateSelected && selectedTemplate && (
          <TemplateDetails>
            <DetailRow>
              <FaFileContract />
              <span><strong>Tipo:</strong> {selectedTemplate.template_type}</span>
            </DetailRow>
            <DetailRow>
              <FaInfoCircle />
              <span><strong>Descrição:</strong> {selectedTemplate.template_description || 'Sem descrição'}</span>
            </DetailRow>
            <DetailRow>
              <FaCalendarAlt />
              <span><strong>Criado em:</strong> {formatDate(selectedTemplate.created_at)}</span>
            </DetailRow>
            {selectedTemplate.updated_at && (
              <DetailRow>
                <FaCalendarAlt />
                <span><strong>Atualizado em:</strong> {formatDate(selectedTemplate.updated_at)}</span>
              </DetailRow>
            )}
          </TemplateDetails>
        )}



        {!isTemplateSelected && (
          <>
            <Divider />
            <Button onClick={() => setIsOpenListTemplates(true)}>
              Selecionar Contrato
            </Button>
          </>
        )}
      </Card>

      {isOpenListTemplates && (
        <ModalListTemplates
          onRequestClose={() => setIsOpenListTemplates(false)}
          isOpen={isOpenListTemplates}
          onSelectAlert={onSelectTemplate}
        />
      )}
    </>
  );
}