import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUpload, FaEdit, FaExclamationTriangle, FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Importa a função uuid v4
import { iDetailsVehicleData, updatePhotoVehicle } from '../repository/MapsRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import ClientIcon from './ClientIcon';
import VehicleIcon from '../../StreamCam/widgets/VehicleIcon';
import VehicleIconMap from './VehicleIconMap';
import VehiclePhotoComp from './VehiclePhotoComp';

// Interfaces
interface InfoVehicleMapsProps {
  item: iDetailsVehicleData;
}

// Styled Components
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 315px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const BoxActions = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 6px;
    `;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0 10px;
  color: #555;
  font-weight: 600;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  gap: 4px; /* Espaço entre o ícone e o texto */

  &:hover {
    color: #000;
  }

  svg {
    font-size: 16px; /* Ajusta o tamanho do ícone */
  }
`;

const InfoVehicleMaps: React.FC<InfoVehicleMapsProps> = ({ item }) => {

  const [isLoading, setIsLoading] = useState(false); // Estado de loading durante o upload
  const { addNotification } = useNotifications();


  return (
    <CardContainer>
      <Subtitle>Veículo ( Cod.: {item.idveiculo})</Subtitle>
      <VehiclePhotoComp
        filename={item.vehicle_photo}
        idclient={item.idcliente}
        idempresa={item.idempresa}
        idvehicle={item.idveiculo}
      />
      <Header>
        <VehicleIconMap vehicleIcon={item.veiculo_icon} iconType={item.icon_type} />
        <BoxHeader>
          <Title>{item.veiculo_placa}</Title>
          <Subtitle>{item.veiculo_marca} / {item.veiculo_model}</Subtitle>
        </BoxHeader>
      </Header>
      <Divider />
      <BoxActions>
        <ActionButton onClick={console.log} disabled={isLoading}>
          <FaEdit /> Editar
        </ActionButton>
      </BoxActions>
    </CardContainer>
  );
};

export default InfoVehicleMaps;
