

import React, { useEffect, useState } from 'react';
import { reqChip, resResumeChips, itemChips } from '../../../interfaces/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { SetEquipInChip, alterApn, alterContract, deleteChips, getListChips, getResumeChips, removerEquip } from '../Gateways/chipsGateway';
import styled from 'styled-components';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import { changeAlert } from '../../../redux/alertServiceSlice';
import ChipsCadForm from './ChipsCadForm';
import { Column } from 'react-data-grid';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ButtonRefresh from '../../../components/datagridComp/ButtonRefresh';
import ColumnSelect from '../../../components/datagridComp/ColumnSelect';
import { typeMethods } from '../../../components/datagridComp/ConfirmationDialog';
import "../../../styles/datagrid/datagrid.css"
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import ButtonAdd from '../../../components/datagridComp/ButtonAdd';
import { BtnCheckBox, ContainerInfo, InfoBox } from '../Styles/StyleChips';
import ChipCardList, { iChipsResume } from '../Components/ChipCardList';
import Sidebar from '../../../components/datagridComp/SideBar';
import SidebarActionChip, { typeMethodsSide } from '../Components/SidebarActionChip';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { getColumnsChips } from '../Utils/ChipsColumns';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { handleSearch } from '../../../utils/searchUtils';
import { FilterKeys } from '../Utils/tools';

const ContainerTable = styled.div`
padding: 6px;
    height: calc(66vh);
    overflow: auto;
`

const ContainerSearch = styled.div`
padding: 6px;
display: flex;
flex-direction: column;
    justify-content: space-between;
    > input {
        margin: 0;
        padding: 8px;
        border: 1px solid #7F7F7F;
        border-radius: 7px;
        width: 250px;
  }
`


type filterSelected = {
    allRecords: boolean
    allStock: boolean
    stockChips: boolean
    stockEquipOnline: boolean
    stockChipWithEquip: boolean
    allInstalled: boolean
    installedOnline: boolean
    installedOffline: boolean
    installedPaid: boolean
    installedNoPaid: boolean
    hasDelete: boolean
    hasCancelContract: boolean
    hasActivateContract: boolean
    hasInactivateContract: boolean
}

let valuesFilter: filterSelected = {
    allRecords: true,
    allStock: false,
    stockChips: false,
    stockEquipOnline: false,
    stockChipWithEquip: false,
    allInstalled: false,
    installedOnline: false,
    installedOffline: false,
    installedNoPaid: false,
    installedPaid: false,
    hasDelete: false,
    hasCancelContract: false,
    hasActivateContract: false,
    hasInactivateContract: false
}

const pageSize = 300;

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;


const optionsActions = [
    { value: 'CANCEL_CHIP', label: 'Contrato à Cancelar' },
    { value: 'ACTIVE_CHIP', label: 'Ativar Chip' },
    { value: 'DISABLE_CHIP', label: 'Desativar Chip' },
    { value: 'REMOVE_EQUIP', label: 'Remover Equipamento' },
    { value: 'DELETE_CHIPS', label: 'Apagar Chips' },
    { value: 'ALTER_PROVIDER', label: 'Alterar Fornecedor / APN' },
    //{ value: 'ADA', label: 'Alterar data Ativação' },

];

let chipToAction: itemChips | undefined;

interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const DELETE_CHIPS = 'DELETE_CHIPS'
const REMOVE_EQUIP = 'REMOVE_EQUIP'
const CANCEL_CHIP = 'CANCEL_CHIP'
const ACTIVE_CHIP = 'ACTIVE_CHIP'
const DISABLE_CHIP = 'DISABLE_CHIP'
const ALTER_PROVIDER = 'ALTER_PROVIDER'
const SELECT_DEVICE = 'SELECT_DEVICE'

const ListChipsPage: React.FC = () => {
    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [ResumeChips, setResumeChips] = React.useState<resResumeChips>();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [ShowForm, setShowForm] = React.useState<boolean>(false);

    const [dataCategoryResume, setDataCategoryResume] = React.useState<iChipsResume[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const [activeFilterKey, setActiveFilterKey] = useState<string | null>(null);

    //sidebar
    const handleCloseSideBar = () => setSideContent(undefined);
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<itemChips[]>([]);
    const [columnsFilter, setColumnsFilter] = useState<Column<itemChips>[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columnsFilter.map(column => column.key.toString()));

    const filteredRows = React.useMemo(() => {
        // Primeiro, aplica os filtros
        let filteredData = rows;

        // if (activeFilterKey === FilterKeys.INSTALLED_OFFLINE) {
        //     filteredData = filteredData.filter(row => row.status_conn === 'OFFLINE');
        // } else if (activeFilterKey === FilterKeys.ONLINE_CHIPS) {
        //     filteredData = filteredData.filter(row => row.status_conn === 'ON-LINE');
        // } else if (activeFilterKey === FilterKeys.NEVER_CONN) {
        //     filteredData = filteredData.filter(row => row.status_conn === 'NUNCA CONECTOU');
        // }

        // Em seguida, aplica a pesquisa
        if (findInputTerms) {
            filteredData = handleSearch(filteredData, findInputTerms);
        }

        return filteredData;
    }, [activeFilterKey, rows, findInputTerms, handleSearch]);

    const handleEditChip = (item: itemChips) => {
        chipToAction = item
        setShowForm(true)
    }
    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }


    async function recalcCards() {
        if (!ResumeChips) return
        const chipsResumeData: iChipsResume[] = [
            { category: "Total de Chips", category_icon: "", total_amount: ResumeChips.totalChips ?? 0, isSelected: activeFilterKey === FilterKeys.ALL_RECORDS, filterKey: FilterKeys.ALL_RECORDS },
            { category: "Total Estoque", category_icon: "", total_amount: ResumeChips.totalEstoque ?? 0, isSelected: activeFilterKey === FilterKeys.ALL_STOCK, filterKey: FilterKeys.ALL_STOCK },
            { category: "Chips Novos", category_icon: "", total_amount: ResumeChips.estoqueChips ?? 0, isSelected: activeFilterKey === FilterKeys.STOCK_CHIPS, filterKey: FilterKeys.STOCK_CHIPS },
            { category: "Estoque On-Line", category_icon: "", total_amount: ResumeChips.estoqueEquipOnline ?? 0, isSelected: activeFilterKey === FilterKeys.STOCK_EQUIP_ONLINE, filterKey: FilterKeys.STOCK_EQUIP_ONLINE },
            { category: "Estoque c/ Equipamento", category_icon: "", total_amount: ResumeChips.estoqueEquipWithChip ?? 0, isSelected: activeFilterKey === FilterKeys.STOCK_CHIP_WITH_EQUIP, filterKey: FilterKeys.STOCK_CHIP_WITH_EQUIP },
            { category: "Ativos", category_icon: "", total_amount: ResumeChips.hasActivateContract ?? 0, isSelected: activeFilterKey === FilterKeys.HAS_ACTIVATE_CONTRACT, filterKey: FilterKeys.HAS_ACTIVATE_CONTRACT },
            { category: "Inativos", category_icon: "", total_amount: ResumeChips.hasInactivateContract ?? 0, isSelected: activeFilterKey === FilterKeys.HAS_INACTIVATE_CONTRACT, filterKey: FilterKeys.HAS_INACTIVATE_CONTRACT },
            { category: "À Cancelar", category_icon: "", total_amount: ResumeChips.hasCancelContract ?? 0, isSelected: activeFilterKey === FilterKeys.HAS_CANCEL_CONTRACT, filterKey: FilterKeys.HAS_CANCEL_CONTRACT },
            { category: "À Deletar", category_icon: "", total_amount: ResumeChips.hasDelete ?? 0, isSelected: activeFilterKey === FilterKeys.HAS_DELETE, filterKey: FilterKeys.HAS_DELETE },
            { category: "Total Instalados", category_icon: "", total_amount: ResumeChips.totalInstalado ?? 0, isSelected: activeFilterKey === FilterKeys.ALL_INSTALLED, filterKey: FilterKeys.ALL_INSTALLED },
            { category: "Instalado Online", category_icon: "", total_amount: ResumeChips.instaladoOnline ?? 0, isSelected: activeFilterKey === FilterKeys.INSTALLED_ONLINE, filterKey: FilterKeys.INSTALLED_ONLINE },
            { category: "Instalado OffLine", category_icon: "", total_amount: ResumeChips.instaladoOffline ?? 0, isSelected: activeFilterKey === FilterKeys.INSTALLED_OFFLINE, filterKey: FilterKeys.INSTALLED_OFFLINE },
            { category: "Instalado Inadiplentes", category_icon: "", total_amount: ResumeChips.instaladoInadiplente ?? 0, isSelected: activeFilterKey === FilterKeys.INSTALLED_NO_PAID, filterKey: FilterKeys.INSTALLED_NO_PAID },
            { category: "Instalado Pagos", category_icon: "", total_amount: ResumeChips.instaladoPago ?? 0, isSelected: activeFilterKey === FilterKeys.INSTALLED_PAID, filterKey: FilterKeys.INSTALLED_PAID },
        ];
        setDataCategoryResume(chipsResumeData);
    }

    const handleFilter = (item: string) => {
        setActiveFilterKey(prevKey => (prevKey === item ? null : item));
        let filter: filterSelected = {
            allRecords: false,
            allStock: false,
            stockChips: false,
            stockEquipOnline: false,
            stockChipWithEquip: false,
            allInstalled: false,
            installedOnline: false,
            installedOffline: false,
            installedPaid: false,
            installedNoPaid: false,
            hasDelete: false,
            hasCancelContract: false,
            hasActivateContract: false,
            hasInactivateContract: false
        }
        filter[item as keyof filterSelected] = true
        console.log(filter, item)
        valuesFilter = filter
        reqGetListChips();
        deselectAll()
    }





    async function reqResumeChips() {
        try {
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098]

            }
            const result = await getResumeChips(payload);
            setResumeChips(result)
            reqGetListChips()
        } catch (e) {
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ListTasksDelivery]', error)
        }
    }
    async function reqGetListChips() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                //idEmpresas: [49, 20250087, 20250098],
                estoqueChips: valuesFilter.stockChips,
                AllStockChips: valuesFilter.allStock,
                onlineChips: valuesFilter.installedOnline,
                instaladoInadiplentes: valuesFilter.installedNoPaid,
                instaladoPagos: valuesFilter.installedPaid,
                estoqueEquipWithChip: valuesFilter.stockChipWithEquip,
                estoqueEquipOnline: valuesFilter.stockEquipOnline,
                totalInstalado: valuesFilter.allInstalled,
                instaladoOffline: valuesFilter.installedOffline,
                instaladoOnline: valuesFilter.installedOnline,
                hasDelete: valuesFilter.hasDelete,
                hasCancelContract: valuesFilter.hasCancelContract,
                hasActivateContract: valuesFilter.hasActivateContract,
                hasInactivateContract: valuesFilter.hasInactivateContract
            }
            const result = await getListChips(payload);
            if (result) {
                setRows(result)
            }
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }

    const removeEquip = async (reason?: string) => {
        try {
            setIsLoading(true)
            let payload = {
                ids: Array.from(selectedRows),
                reason: reason ?? 'Não informado'
            }
            await removerEquip(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Equipamentos removidos com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }
    const cancelChip = async (value: any) => {
        try {
            setIsLoading(true)
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification ?? 'Não informado'
            }
            await removerEquip(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Chips cancelados com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    }



    useEffect(() => {

        (async () => {
            setColumnsFilter(columns)
            reqResumeChips();
        })();
    }, []);


    React.useEffect(() => {
        recalcCards()
    }, [activeFilterKey]);

    React.useEffect(() => {
        recalcCards()
    }, [ResumeChips]);




    const handleRefresh = () => {
        reqResumeChips();
    }
    const handleFormChip = () => {
        chipToAction = undefined
        setShowForm(true)
    }
    const handleCancelFormChip = () => {
        setShowForm(false)
    }
    const handleSavedChip = () => {
        setShowForm(false)
        reqResumeChips();
    }




    // const handleFilter = (filterKey: string) => {
    //     deselectAll()
    //     setActiveFilterKey(prevKey => (prevKey === filterKey ? null : filterKey));
    // }


    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }
        switch (action) {
            case 'REMOVE_EQUIP':
                setSideContent(REMOVE_EQUIP)
                break
            case 'DELETE_CHIPS':
                setSideContent(DELETE_CHIPS)
                break
            case 'CANCEL_CHIP':
                setSideContent(CANCEL_CHIP)
                break
            case 'ACTIVE_CHIP':
                setSideContent(ACTIVE_CHIP)
                break
            case 'DISABLE_CHIP':
                setSideContent(DISABLE_CHIP)
                break
            case 'ALTER_PROVIDER':
                setSideContent(ALTER_PROVIDER)
                break
        }
    };

    const handleAPNSelected = async (value: any) => {
        try {
            console.log(value)
            setIsLoading(true)
            let payload = {
                chip_fornecedor: value.provider.id_provider,
                chip_apn: value.provider.id,
                ids: Array.from(selectedRows)
            }
            const result = await alterApn(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Fornecedor / APN alterado com sucesso', 'success');
            handleCloseSidebar()

        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };
    const SendEquipelected = async (payload: any) => {
        if (payload.device === undefined) {
            addNotification('', 'Selecione um equipamento', 'warning');
            return
        }
        if (chipToAction === undefined) {
            addNotification('Falha ao selecionar Chip', 'Repita a operação', 'error');
            return
        }
        const { iddisp, equip_imei } = payload.device
        try {
            let input = {
                iddisp: iddisp,
                idchip: chipToAction.id
            }
            await SetEquipInChip(input);
            reqResumeChips();
            addNotification('Operação realizada com Sucesso!', `Equipamento ${equip_imei} vinculado ao chip cód: ${chipToAction.id}`, 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }
    };

    const handleConfirmeDelete = async (value: any) => {
        try {
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification ?? 'Não informado'
            }
            const result = await deleteChips(payload);
            deselectAll()
            reqResumeChips();
            addNotification('', 'Chips apagadas com sucesso', 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };

    const handleAlterContract = async (value: any) => {
        try {
            let payload = {
                ids: Array.from(selectedRows),
                reason: value.justification,
                status: value.status
            }
            console.log(payload)
            const result = await alterContract(payload);
            deselectAll()
            reqResumeChips();
            addNotification('Alterar contrato', `Contrato alterado: ${value.status}`, 'success');
            handleCloseSidebar()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
        }

    };


    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleCloseSidebar = () => {
        setSideContent(undefined);
    };

    const handleSelectEquip = (item: itemChips) => {
        chipToAction = item
        setSideContent(SELECT_DEVICE)
    }

    const columns = getColumnsChips(handleEditChip, handleSelectEquip)

    return (
        <>
            {!ShowForm && <>
                <ChipCardList categories={dataCategoryResume} onClick={handleFilter} />
                <>
                    <SearchAndSelectContainer>
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <ButtonRefresh onClick={reqResumeChips} />
                        <ButtonAdd onClick={handleFormChip} />
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            visibleColumns={visibleColumns}
                            onRowSelect={handleRowSelect}
                        /></ContainerTable>

                </>
            </>}
            <Sidebar isOpen={sideContent === DELETE_CHIPS} onClose={handleCloseSidebar} title={'Apagar Chips'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === DELETE_CHIPS}
                    type={sideContent as typeMethodsSide}
                    message="Apagar os Chips selecionadas!"
                    onConfirm={handleConfirmeDelete}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === REMOVE_EQUIP} onClose={handleCloseSidebar} title={'Remover Equipamentos'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === REMOVE_EQUIP}
                    type={sideContent as typeMethodsSide}
                    message="Remover equipamentos dos chips selecionadas!"
                    onConfirm={removeEquip}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === CANCEL_CHIP} onClose={handleCloseSidebar} title={'Cancelar Chips'}>
                <SidebarActionChip
                    requireJustification={true}
                    isShow={sideContent === CANCEL_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Solicitar o Cancelamento dos chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === ACTIVE_CHIP} onClose={handleCloseSidebar} title={'Ativar Chips'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === ACTIVE_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Ativar chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === DISABLE_CHIP} onClose={handleCloseSidebar} title={'Desativar Chips'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === DISABLE_CHIP}
                    type={sideContent as typeMethodsSide}
                    message="Desativar chips selecionadas!"
                    onConfirm={handleAlterContract}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === ALTER_PROVIDER} onClose={handleCloseSidebar} title={'Alterar Fornecedor'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === ALTER_PROVIDER}
                    type={sideContent as typeMethodsSide}
                    message="Alterar fornecedor/APN chips selecionadas!"
                    onConfirm={handleAPNSelected}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === SELECT_DEVICE} onClose={handleCloseSidebar} title={'VINCULAR EQUIPAMENTO'}>
                <SidebarActionChip
                    requireJustification={false}
                    isShow={sideContent === SELECT_DEVICE}
                    type={sideContent as typeMethodsSide}
                    message="Vincular Equipamento ao Chip"
                    onConfirm={SendEquipelected}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>

            {ShowForm && <ChipsCadForm onCancelClick={handleCancelFormChip} onSaveClick={handleSavedChip} initialData={chipToAction} />}
        </>
    )

}

export default ListChipsPage

