import React, { useEffect } from 'react';
import { Column } from 'react-data-grid';
import { styled } from 'styled-components';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleSearch } from '../../../utils/searchUtils';
import { getTemplatesByEmpresa, iTemplateScreen } from '../repository/TemplateRepo';
import ModalComp from '../../../components/datagridComp/ModalComp';
import SearchField from '../../../components/datagridComp/SearchField';
import DataGridComp from '../../../components/datagridComp/DataGridComp';


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
    padding: 5px;
`;


interface CompProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: any) => void;
}

const ModalListTemplates: React.FC<CompProps> = ({ onRequestClose, onSelectAlert, isOpen }) => {
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<iTemplateScreen[]>([]);


    const columns: Column<iTemplateScreen>[] = [
        { key: 'id', name: 'Cód', width: 80 },
        {
            key: 'template_description', name: 'Descr', width: 250,
        },
        {
            key: 'template_type', name: 'Formato', width: 90,
        },
        {
            key: 'unique_id', name: 'Token', width: 280,
        },
    ];

    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);

    const getDados = async () => {
        let resp = await getTemplatesByEmpresa()
        setRows(resp);
    }

    useEffect(() => {
        if (isOpen && rows.length === 0) {
            getDados();
        }
    }, [isOpen]);

    // Função que lida com a seleção de uma linha
    const handleSingleSelect = (row: any) => {
        onSelectAlert(row);
        onRequestClose();
    };

    return (
        <ModalComp title="Lista de Contratos" subtitle="" onClose={onRequestClose} isShowCloseButton={true}>
            <ModalContent>
                <SearchAndSelectContainer>
                    <SearchField onSearch={setFindInputTerms} />
                    <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
                        <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                    </BtnFaIcon >
                </SearchAndSelectContainer>
                <ContainerInfo>
                    <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                </ContainerInfo>
                <ContainerTable>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={handleSingleSelect}
                        onRowSelect={setSelectedRows}
                    />
                </ContainerTable>
            </ModalContent>
        </ModalComp>
    );
};

export default React.memo(ModalListTemplates);


const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 800px;
`;


const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

type BtnFaIconProps = {
    iconColor?: string; // Permite que a cor seja opcional
};


const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
`

const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
