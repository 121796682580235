import { Column, SelectColumn } from 'react-data-grid';
import { lineChip } from '../Views/ChipsInportsPage';
import ChipStatusContract from '../Components/ChipStatusContract';

export const getColumnsExcelChips = (): Column<lineChip>[] => {
    return [
        {
            ...SelectColumn,
            width: 150,
        },

        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'status',
            name: 'Status',
            width: 150
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 110,
            renderCell: (props: any) => (
                <ChipStatusContract status={props.row.contract_status} />
            )
        },
        {
            key: 'idproviderExcel',
            name: 'ID Forn.',
            width: 65
        },
        {
            key: 'serial_provider',
            name: 'Serial Forn.',
            width: 220
        },
        {
            key: 'number_provider',
            name: 'Linha Forn.',
            width: 150
        },
        {
            key: 'operadora_provider',
            name: 'Oper Forn.',
            width: 100
        },
        {
            key: 'conexao',
            name: 'Conexão',
            width: 100
        },
        {
            key: 'id_registed',
            name: 'Cód Cad',
            width: 60
        },
        {
            key: 'idempresa',
            name: 'Empresa',
            width: 80
        },
        {
            key: 'idproviderClient',
            name: 'Forn. Reg',
            width: 65
        },
        {
            key: 'serial_registed',
            name: 'Serial Cad.',
            width: 220
        },
        {
            key: 'number_registed',
            name: 'Linha Cad.',
            width: 150
        },
        {
            key: 'operadora_registed',
            name: 'Oper Cad.',
            width: 100
        },
    ]
}