import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListClients(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `clients`,
        "GET"
    );
    return response;
}


export async function createClient(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `clients/create`,
        "POST",
        payload
    );
    return response;
}
export async function createVehicle(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `vehicle/create-by-excel`,
        "POST",
        payload
    );
    return response;
}

export async function getEquipByImei(imei: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `equip/imei?imei=${imei}`,
        "GET"
    );
    return response;
}

export async function getClientByLogin(value: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `client/idclient-login?login=${value}`,
        "GET"
    );
    return response;
}


export async function updateClient(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `clients`,
        "PUT",
        payload
    );
    return response;
}


export interface iClients {
    id: number
    Email: string
    nome: string
    Cpf: string
    Rg: string
    Endereco: string
    num: string
    Bairro: string
    Cidade: string
    Estado: string
    Celular: string
    Telefone: string
    Mensalidade: string
}




export interface iClientsExcel {
    id: number
    TipoPessoa: number
    Email: string
    Senha: string
    Nome: string
    CPF_CNPJ: string
    RG: string
    Endereco: string
    Num: string
    CEP: string
    Bairro: string
    Cidade: string
    Estado: string
    Celular: string
    Telefone: string
    Mensalidade: string
}
export interface iVehiclesExcel {
    id: number
    idcliente: number
    Placa: string
    Marca: string
    Modelo: string
    Ano: string
    Imei: string
    Login: string
}
export interface iVehiclesListExcel {
    id: number
    status: string
    idempresa: number
    idcliente: number
    iddisp: number
    plate_number: string
    vehicle_model: string
    vehicle_brand: string
    vehicle_year: string
    login: string
    imei: string
}



export interface IFormClientsData {
    id: number;
    status: string;
    cliente_idempresa: number;
    cliente_tipo: number;
    cliente_cpf: string;
    cliente_rg?: string;
    cliente_nome: string;
    cliente_email: string;
    cliente_tel1: string;
    cliente_tel2: string;
    cliente_ender: string;
    cliente_bairro: string;
    cliente_numero: string;
    cliente_cep: string;
    cliente_cidade: string;
    cliente_uf: string;
    login: string;
    password: string;

}


export interface iVehicleSaveExportExcel {
    veiculo_idcliente: number;
    veiculo_idempresa: number;
    veiculo_iddisp: number;
    veiculo_placa: string;
    veiculo_fipe_name_marcas: string;
    veiculo_fipe_name_modelo: string;
    veiculo_fipe_name_ano: string;
};