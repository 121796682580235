import React, { useState } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { handleSearch } from '../../../utils/searchUtils';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { getColumnsClientes } from '../Utils/ClientsColumns';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { getListClients } from '../repository/SettingsRepo';
import { styled } from 'styled-components';


interface props {
    selectedRows: ReadonlySet<number>;
    setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<number>>>;
}

const ContractSelectClients: React.FC<props> = ({ selectedRows, setSelectedRows }) => {

    const { addNotification } = useNotifications();

    //datagrid

    const [rows, setRows] = React.useState<any[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);

    const columns = getColumnsClientes();


    async function getListInServer() {
        try {
            const result = await getListClients();
            console.log('result:', result);
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }


    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    React.useEffect(() => {
        getListInServer();
    }, []);


    return (
        <Card>
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}
        </Card>
    )

}
export default ContractSelectClients

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  max-width: 38rem;
  width: 100%;
  margin: 10px;
`;