import React from 'react';
import styled from 'styled-components';
import { FaSave, FaTimes } from 'react-icons/fa';

const Card = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
  max-width: 28rem;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const Button = styled.button<{ variant: 'primary' | 'secondary'; disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s;
  
  ${props => props.variant === 'primary' ? `
    background: #0ea5e9;
    color: white;
    &:hover {
      background: ${props.disabled ? '#0ea5e9' : '#0284c7'};
    }
  ` : `
    background: #f1f5f9;
    color: #64748b;
    &:hover {
      background: #e2e8f0;
    }
  `}

  ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

interface ActionCardProps {
    onSave: () => void;
    onCancel: () => void;
    isSelectedTemplate: boolean;
    isSelectedClients: boolean;
}

export function ActionCard({ onSave, onCancel, isSelectedTemplate, isSelectedClients }: ActionCardProps) {
    const isSaveDisabled = !isSelectedTemplate || !isSelectedClients;

    return (
        <Card>
            <ButtonContainer>
                <Button variant="secondary" onClick={onCancel}>
                    <FaTimes />
                    Cancelar
                </Button>
                <Button
                    variant="primary"
                    onClick={onSave}
                    disabled={isSaveDisabled}
                >
                    <FaSave />
                    Salvar
                </Button>
            </ButtonContainer>
        </Card>
    );
}