import React from 'react';
import { styled } from 'styled-components';
import ModalComp from '../../../../components/datagridComp/ModalComp';
import IconGrid from '../../../IconsMap/IconGrid';
import { updateIconVehicle } from '../../repository/MapsRepo';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { extractErrorMessage } from '../../../../utils/CustomErros';
import { useNotifications } from '../../../../Notifications/NotificationContext';



interface CompProps {
    isOpen: boolean;
    onClose: () => void;
    idVehicle: number;
}

const ModalListIcons: React.FC<CompProps> = ({ onClose, idVehicle }) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { addNotification } = useNotifications();

    const handleSelectIcon = async (icon: string, iconType: boolean) => {

        let payload = {
            iconType: iconType === true ? 1 : 0,
            idvehicle: idVehicle,
            icon: icon
        }

        try {
            setIsLoading(true);
            await updateIconVehicle(payload);
            addNotification('', 'Ação realizada com sucesso', 'success');
            onClose();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <ModalComp title="Selecionar um ícone" subtitle="" onClose={onClose} isShowCloseButton={true}>
                <ModalContent>
                    <IconGrid
                        onSelectIcon={handleSelectIcon}
                    />
                </ModalContent>
            </ModalComp>
            {isLoading && <ModalComp title={""} subtitle="" onClose={() => setIsLoading(false)}>
                <LoadingIndicator message={"Processando..."} />
            </ModalComp>}
        </>
    );
};

export default React.memo(ModalListIcons);


const ModalContent = styled.div`
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: 700px;
`;