

import React, { useEffect, useState } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerFlexInline, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../Style/StyleDevices';
import { read, utils } from 'xlsx';

import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { handleSearch } from '../../../utils/searchUtils';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';

import { AuthConfig } from '../../../Config/AuthContext';
import Sidebar from '../../../components/datagridComp/SideBar';

import CustomError, { extractErrorMessage } from '../../../utils/CustomErros';
import { createDevice, getListEquipRegistered, IDevicesDataExcel, iDevicesExcel, iEquipment, registerEquip, saveEquip } from '../Repository/DevicesRepo';
import { getColumnsDevicesExcel } from '../Utils/DevicesColumnsExcel';




var reader = new FileReader();
const EDIT_FORM = 'EDIT_FORM';

let equipRegistered: iEquipment[] = []

const ImportDevicesTab: React.FC = () => {

    const { user } = AuthConfig()
    const { addNotification } = useNotifications();

    const [selectedFile, setSelectedFile] = React.useState<File>()
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [ClientEdit, setClientEdit] = React.useState<IDevicesDataExcel>();


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<IDevicesDataExcel[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);



    const handleEditItem = (row: IDevicesDataExcel) => {
        setClientEdit(row);
        setSideContent(EDIT_FORM);
    };

    const columns = getColumnsDevicesExcel(handleEditItem);

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    useEffect(() => {
        if (isFilePicked) {
            handleSubmission();
        }
    }, [isFilePicked]);


    const handleSubmission = async () => {
        try {

            reader.onload = function (e) {
                var f = e.target?.result;
                const wb = read(f, { type: 'binary' });
                const data = utils.sheet_to_json<iDevicesExcel>(wb.Sheets[wb.SheetNames[0]]);
                console.log('[ReadExcel]', data)
                let newdata = data.map((item, i) => {
                    let out: IDevicesDataExcel = {
                        id: i,
                        equip_imei: item.Imei ?? '',
                        equip_status: 'Estoque',
                        equip_modelo: 0,
                        equip_marca: 0,
                        equip_idempresa: user?.idempresa as number,
                        equip_modelo_name: item.ModeloEquipamento ? item.ModeloEquipamento.toUpperCase() : '',
                        status: 'Pendente',
                        protocol: item.Protocolo ? item.Protocolo.toUpperCase() : '',
                        LoRaWANId: item.object_owner
                    }
                    return out

                })
                setRows(newdata)
                addNotification('Leitura do Arquivo', 'Arquivo Extraido com sucesso', 'success');

            }
            reader.readAsArrayBuffer(selectedFile as unknown as Blob);
        } catch (e) {
            let error = e as Error
            console.log('[ReadExcel]', error)
            addNotification('Erro ao ler arquivo', error.message, 'error');
        }
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }



    const CancelFormClient = () => {
        setClientEdit(undefined);
        setSideContent(undefined);
    }


    const alterStatus = (id: number, status: string) => {
        let newRows = [...rows]
        let index = newRows.findIndex((item) => item.id === id)
        newRows[index].status = status
        setRows(newRows)
    }

    const sendToServer = async (item: IDevicesDataExcel) => {
        alterStatus(item.id, 'Enviando...')
        try {
            await saveEquip(item)
            alterStatus(item.id, 'Salvo com sucesso')
        } catch (e) {
            const errorMessage = extractErrorMessage(e);
            alterStatus(item.id, '[Erro] ' + errorMessage)
        }
    }

    const getListEquipRegisteredInServer = async () => {
        try {
            let register = await getListEquipRegistered()
            if (register.length === 0) {
                addNotification('Erro ao buscar Equipamentos', 'Nenhum Equipamento Registrado', 'error');
                return
            }
            equipRegistered = register
            handleValidEquip()
        } catch (e) {
            const errorMessage = extractErrorMessage(e);
            addNotification('Erro ao buscar registros', errorMessage, 'error');
        }
    }

    const handleSave = async () => {
        if (selectedRows.size === 0) {
            addNotification('Salvar Registros', 'Nenhum registro selecionado', 'error');
            return
        }
        await getListEquipRegisteredInServer()
    }

    const getEquipRegistered = (model: string) => {
        if (!model || String(model).trim().length < 2) {
            throw new CustomError(
                400,
                'Modelo Inválido',
                1020,
                'GET_DEVICE_VALIDATION_ERROR'
            );
        }
        return equipRegistered.find((item) => item.equip_model === model)
    }

    const handleValidEquip = async () => {

        let selected = rows.filter((item) => selectedRows.has(item.id))
        for (let i = 0; i < selected.length; i++) {
            try {
                let item = selected[i]
                alterStatus(item.id, 'Validando Modelo...')
                let eq = getEquipRegistered(item.equip_modelo_name)
                if (!eq) {
                    alterStatus(item.id, 'Cadastrando Modelo..')
                    let eq = await registerEquip({
                        equip_producer_id: 1,
                        equip_model: item.equip_modelo_name,
                        equip_type: item.equip_modelo_name,
                        equip_group: item.protocol
                    })
                    equipRegistered.push(eq)
                }
                alterStatus(item.id, 'Modelo Validado!')
            } catch (e) {
                const errorMessage = extractErrorMessage(e);
                alterStatus(selected[i].id, '[Erro] ' + errorMessage)
            }
        }
        handleSaveEquip()

    }

    const handleSaveEquip = async () => {
        try {
            let selected = rows.filter((item) => selectedRows.has(item.id))
            for (let i = 0; i < selected.length; i++) {
                let item = selected[i]
                let eq = getEquipRegistered(item.equip_modelo_name)
                console.log('[Equip]', eq)
                if (eq) {
                    item.equip_marca = eq.equip_producer_id
                    item.equip_modelo = eq.id
                }
                await sendToServer(item)
            }
        } catch (e) {
            const errorMessage = extractErrorMessage(e);
            addNotification('Erro ao salvar equipamento', errorMessage, 'error');
        }
    }


    const handleUpdateList = (value: IDevicesDataExcel) => {
        console.log('[UpdateList]', value)
        let newRows = [...rows]
        let index = newRows.findIndex((item) => item.id === value.id)
        newRows[index] = value
        setRows(newRows)
        CancelFormClient();
    };

    return (
        <>
            <ContainerFlexInline>
                <input type="file" name="file" onChange={changeHandler} />
            </ContainerFlexInline>
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={handleSubmission}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Recarregar Excel
                </BtnFaIcon >
                <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleSave}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Salvar Selecionados
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}


            {/* {ClientEdit && <Sidebar width='700px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormClient} title={'Cadastro de Cliente'}>
                <FormClients
                    onCancel={CancelFormClient}
                    onEditReturn={handleUpdateList}
                    initialData={ClientEdit}
                    isExcelForm={true}
                />
            </Sidebar>} */}
        </>
    )

}
export default ImportDevicesTab