import { httpAxiosV4 } from "../../../gateways/Axios";



export async function getVehiclesByStream(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `panel/vehicles`,
        "POST",
        payload
    );
    return data;
}
export async function getApiOneVehicle(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `vehicle/panel?idvehicle=${payload.idvehicle}`,
        "GET",
        payload
    );
    return data;
}
export async function getApiVehicleMonthChart(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `vehicle/resume?idvehicle=${payload}`,
        "GET",
        payload
    );
    return data;
}
export async function getApiTrajetos(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `geoinfo/positions`,
        "POST",
        payload
    );
    return data;
}
export async function getApiEventos(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `events/list-events`,
        "POST",
        payload
    );
    return data;
}
export async function getApiSoptRoutes(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `events/stoprouter-norecalc`,
        "POST",
        payload
    );
    return data;
}
export async function updatePhotoVehicle(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `painel/update-photo-vehicle`,
        "POST",
        payload
    );
    return data;
}

export async function getDetailsVehicle(id: any): Promise<iDetailsVehicleData> {
    const { data } = await httpAxiosV4<any, iDetailsVehicleData>(
        `painel/details-vehicle?idvehicle=${id}`,
        "GET",
    );
    return data;
}

export async function updateVehicleSettings(payload: any, id: number): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `vehicle/update-vehicle-settings?idvehicle=${id}`,
        "POST",
        payload
    );
    return data;
}

export async function updateIconVehicle(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `painel/update-icon-vehicle`,
        "POST",
        payload
    );
    return data;
}

type FinanceStatus = 'PAGO' | 'PENDENTE' | 'ATRASADO'; // Exemplos de possíveis status financeiros

export interface iDetailsVehicleData {
    client_name: string;
    client_address: string;
    client_phone: string;
    finance_status: string | null;
    isFinance: number; // 1 ou 0, poderia ser um boolean mas mantendo conforme o objeto original
    veiculo_placa: string;
    veiculo_marca: string;
    veiculo_model: string;
    veiculo_icon: string;
    vehicle_photo: string;
    idempresa: number;
    idveiculo: number;
    idcliente: number;
    imei: string;
    equip_producer: string;
    equip_model: string;
    equip_id: number;
    chip_id: number | null;
    chip_serial: string | null;
    chip_line: string | null;
    chip_operadora: string | null;
    chip_provider: string | null;
    apelido: string;
    km_total: number;
    vel_media_count: number;
    idmotorista: number | null;
    driver_avatar: string | null;
    driver_name: string | null;
    driver_card: string | null;
    driver_contact: string | null;
    driver_cnh: string | null;
    icon_type: number;
}



export interface EventsData {
    id: number;
    idveiculo: number;
    idcliente: number;
    idempresa: number;
    idmotorista: number;
    idviagem: number;
    iddisp: number;
    idcell: number;
    lat: string;
    lng: string;
    start_lat: string;
    start_lng: string;
    evento: number;
    dlastevento: string;
    sevento: string;
    devento: string;
    tevento: string;
    duraction: number;
    dist: number;
    dist_gps: number;
    ocioso: number;
    status: string;
    dsync: string;
    source: number;
    param1: string;
    param2: string | null;
    param3: string | null;
    apelido: string;
    isrecalc: number;
    isnew: number;
    event_cam: number;
    files_stream: string;
    create_at: string | null;
    delete_at: string | null;
    update_at: string | null;
    is_sync: number;
    dt_evt: string;
    dt_old_evt: string;
    icone: string | null;
    nome: string | null;
    driver_avatar: string;
    driver_name: string;
}



export interface TrajectData {
    id: number;
    tipo: string;
    imei: string;
    iddisp: string;
    idveiculo: number;
    idmotorista: number;
    datahora: string;
    dthr_equip: string;
    online: number;
    header: string;
    mode: string;
    msg_tipo: string;
    fix: string;
    lat: string;
    lng: string;
    speed: number;
    alt: string;
    dir: number;
    nsat: string;
    hdop: string;
    sig: string;
    batdisp: string;
    batveic: string;
    ign: string;
    block: string;
    intervalo: string;
    intervalo2: number;
    in1: string;
    in2: string;
    out1: string;
    out2: string;
    horimetro: number;
    odometer: number;
    dist: number;
    vel_media: number;
    count: number;
    rpm: string;
    temp: string;
    kmequip: string;
}
