import dayjs from "dayjs";


export const FilterKeys = {
    ALL_EXCEL: 'allExcel',
    HAS_REGISTER: 'hasRegister',
    NEW_CHIPS: 'newChips',
    OFFLINE_CHIPS: 'offlineChips',
    ONLINE_CHIPS: 'onlineChips',
    NEVER_CONN: 'neverConnChips',
    CHIPS_DELETE: 'chipsDelete',
    REGISTER_ERROR: 'registerSupplierError',
    TO_UPDATE: 'toUpdate',
    STATUS_CANCEL: 'statusCancel',
    STATUS_DELETE: 'statusDelete',
    STATUS_ACTIVE: 'statusActive',
    STATUS_INACTIVE: 'statusInactive',
    ALL_RECORDS: 'allRecords', // Nova chave
    ALL_STOCK: 'allStock', // Nova chave
    STOCK_CHIPS: 'stockChips', // Nova chave
    STOCK_EQUIP_ONLINE: 'stockEquipOnline', // Nova chave
    STOCK_CHIP_WITH_EQUIP: 'stockChipWithEquip', // Nova chave
    ALL_INSTALLED: 'allInstalled', // Nova chave
    INSTALLED_ONLINE: 'installedOnline', // Nova chave
    INSTALLED_OFFLINE: 'installedOffline', // Nova chave
    INSTALLED_NO_PAID: 'installedNoPaid', // Nova chave
    INSTALLED_PAID: 'installedPaid', // Nova chave
    HAS_DELETE: 'hasDelete', // Nova chave
    HAS_CANCEL_CONTRACT: 'hasCancelContract', // Nova chave
    HAS_ACTIVATE_CONTRACT: 'hasActivateContract', // Nova chave
    HAS_INACTIVATE_CONTRACT: 'hasInactivateContract', // Nova chave

} as const;

export const getStatusConn = (nDiasConn: number): string => {
    if (nDiasConn === -1) {
        return 'NUNCA CONECTOU';
    }
    if (nDiasConn >= 30) {
        return 'OFF-LINE';
    }
    return 'ON-LINE';
}


export const getStatusContract = (status: string): string => {
    console.log(status)
    if (status === 'DELETAR') {
        return 'statusDelete';
    }
    if (status === 'CANCELAR') {
        return 'statusCancel';
    }
    return 'statusActive';
}


// Função para calcular o número de dias
export const calcularDiasComunicacao = (dataString: string): number => {
    // Parse da data fornecida
    const data = dayjs(dataString, 'DD/MM/YYYY HH:mm:ss');

    // Valida se a data é válida
    if (!data.isValid()) {
        return -1;
    }

    // Calcula a diferença em dias entre a data fornecida e a data atual
    const dias = dayjs().diff(data, 'days');

    return dias;
};