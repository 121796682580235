import React, { useState } from 'react';
import styled from 'styled-components';

declare const require: {
    context: (path: string, deep?: boolean, filter?: RegExp) => {
        keys: () => string[];
        (key: string): string;
    };
};
const IconGridWrapper = styled.div`
    height: 70vh;
    overflow: auto;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48px, 1fr));
    gap: 5px;
    padding: 5px;
`;

const IconBox = styled.div<{ isSelected: boolean }>`
    cursor: pointer;
    border: ${(props) => (props.isSelected ? '2px solid blue' : '1px solid #ccc')};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    padding: 8px;
`;

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;

const TabButton = styled.button<{ isActive: boolean }>`
    background-color: ${(props) => (props.isActive ? '#0d7400' : '#e0e0e0')};
    color: ${(props) => (props.isActive ? 'white' : '#333')};
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isActive ? '#0056b3' : '#d6d6d6')};
    }
`;

interface ItemProps {
    onSelectIcon: (icon: string, typeIcon: boolean) => void;
}

const IconGrid: React.FC<ItemProps> = ({ onSelectIcon }) => {
    // Função para carregar os ícones dinamicamente
    const importIconsDefault = () => {
        const context = require.context('../../assets/icons_maps', false, /\.(png|jpe?g|svg)$/);
        return context.keys().map((key) => ({
            name: key.replace('./', ''), // Remove o "./" inicial, preservando o nome original do arquivo
            src: context(key),
        }));
    };

    const importIconsRotate = () => {
        const context = require.context('../../assets/icons_rotate', false, /\.(png|jpe?g|svg)$/);
        return context.keys().map((key) => ({
            name: key.replace('./', ''), // Remove o "./" inicial, preservando o nome original do arquivo
            src: context(key),
        }));
    };


    const [activeTab, setActiveTab] = useState<'standard' | 'rotate'>('standard');

    const icons = activeTab === 'standard'
        ? importIconsDefault()
        : importIconsRotate();


    // Estado do ícone selecionado
    const [selectedIcon, setSelectedIcon] = useState<{ name: string; src: string } | null>(null);

    // Remove a extensão do arquivo
    const getIconName = (iconPath: string) => {
        const parts = iconPath.split('/');
        const fileName = parts[parts.length - 1];
        return fileName.replace(/\.[^/.]+$/, '');
    };

    const handleSelectIcon = (icon: any) => {
        setSelectedIcon(icon);
        onSelectIcon(getIconName(icon.name), activeTab === 'rotate');
    }

    return (
        <>
            {/* Botões para alternar entre os ícones */}
            <TabContainer>
                <TabButton
                    isActive={activeTab === 'standard'}
                    onClick={() => setActiveTab('standard')}
                >
                    Ícones Padrões
                </TabButton>
                <TabButton
                    isActive={activeTab === 'rotate'}
                    onClick={() => setActiveTab('rotate')}
                >
                    Ícones Rotate
                </TabButton>
            </TabContainer>

            <IconGridWrapper>
                <Grid>
                    {icons.map((icon, index) => (
                        <IconBox
                            key={index}
                            isSelected={selectedIcon?.name === icon.name}
                            onClick={() => handleSelectIcon(icon)}
                        >
                            <img
                                src={icon.src}
                                alt={icon.name}
                                style={{
                                    width: '32px',
                                    height: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </IconBox>
                    ))}
                </Grid>
            </IconGridWrapper>
        </>
    );
};

export default IconGrid;
