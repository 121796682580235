import { VehicleData } from "../../StreamCam/StreamInterfaces";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import '../Styles/MapStyles.css';
import ReactDOM from "react-dom";
import VehicleMapComp from "../components/VehicleMapComp";
import { createRoot } from "react-dom/client";
import { checkIsOnline } from "./tools";


interface MarkerProps {
    map: google.maps.Map;
    vehicle: VehicleData;
    onButtonClick: (vehicle: VehicleData) => void;
}

function createAdvanceMarker(
    map: google.maps.Map,
    vehicle: VehicleData,
    onButtonClick: (vehicle: VehicleData) => void
): google.maps.marker.AdvancedMarkerElement {
    const statusTx = checkIsOnline(vehicle.txdtEquip)
    const color = statusTx ? vehicle.ign === 'ON' ? 'green' : 'orange' : 'red';
    const rotation = vehicle.gpscursor;
    const isArrow = vehicle.gpsvel > 1
    const pathIconVehicle = vehicle.icon_type === 1 ? `assets/icons_rotate/${vehicle.icone}.png` : `assets/icons_maps/${vehicle.icone}.png`;
    const labelContent = `
        <div style="position: relative; width: 40px; height: 40px;">
            <div style=" width: 40px; height: 40px; background-color: ${color}; border-radius: 50%; position: absolute; top: 0; left: 0;">
                
                <img src="assets/icons_maps/${vehicle.icone}.png" style=" z-index: 0; width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />
            </div>
            <div class='custom-label-events'>
                ${vehicle.apelido}
            </div>
        </div> 
    `;


    const labelContent_rotate = ` 
    <div style="position: relative; width: 28px; height: 28px;">
        <!-- Ícone centralizado -->
        <div style="width: 28px; height: 28px; display: flex; align-items: center; justify-content: center;">                
            <img 
                src="assets/icons_rotate/${vehicle.icone}.png" 
                style="
                    z-index: 0; 
                    width: 22px; 
                    transform: rotate(${rotation}deg);
                " 
            />
        </div>
        <!-- Texto (apelido) posicionado abaixo sem interferir no ícone -->
        <div class="custom-label-rotate">
            ${vehicle.apelido}
        </div>
    </div> 
`;

    const icone = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: isArrow ? 6 : 0,
        fillColor: color,
        fillOpacity: 1.0,
        strokeColor: color,
        strokeWeight: 2,
        rotation: rotation,
        anchor: new google.maps.Point(0, 4.5),
    };

    const container = document.createElement("div");
    const root = createRoot(container);
    const htmlcontent = vehicle.icon_type === 1 ? labelContent_rotate : labelContent
    root.render(<div dangerouslySetInnerHTML={{ __html: htmlcontent }} />);

    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(vehicle.gpslat), lng: parseFloat(vehicle.gpslng) },
        map: map,
        content: container,
        title: vehicle.apelido
    });

    //const formattedDate = format(new Date(vehicle.date), 'dd/MM/yyyy HH:mm');
    const driverAvatarPath = vehicle.DriverAvatar ? `${process.env.REACT_APP_FILES_SERVER}/avatar-driver/${vehicle.DriverAvatar}` : null;
    const vehicleImagePath = vehicle.vehicle_photo ? `${process.env.REACT_APP_FILES_SERVER}/vehicle-photo/${vehicle.vehicle_photo}` : null;

    const driverInfo = vehicle.DriverName && vehicle.DriverAvatar ? `
        <div style="display: flex; align-items: center; margin-top: 10px;">
            <img src="${driverAvatarPath}" alt="Driver Avatar" style="width: 32px; height: 32px; border-radius: 50%; margin-right: 10px;" />
            <div>
                <p style="margin: 0;">${vehicle.DriverName}</p>
                <p style="margin: 0;">ID: ${vehicle.id_driver}</p>
            </div>
        </div>
    ` : `
        <p>Motorista não identificado</p>
    `;

    const vehiclePhoto = vehicle.vehicle_photo ? `
       <div style="display: flex; align-items: center; padding-top: 2px;">
                    <img src="${vehicleImagePath}" alt="Driver Avatar" style="width: 280px; height: 200px;" />
        </div>` : `<br>`;

    const iconVehicle = `
                    <div style="position: relative; width: 35px; height: 35px;">
                        <img src="${pathIconVehicle}" style=" width: 32px; height: 32px;" />            
                     </div>`;

    const vehicleInfo = `
        <div style="display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 2px;">
                <div style="display: flex;">
                    ${iconVehicle}  
                    <div style="margin-left: 10px;">
                        <h3 style="font-size: 13px;font-weight: 600;">${vehicle.vehicle_placa} / (${vehicle.apelido})</h3>
                        <div style="font-size: 12px;padding-top:3px;">${vehicle.manufacturer_vehicle} / ${vehicle.model_vehicle}</div>                
                    </div>
                </div>
                <button id="infoWindowClose" 
                    style=" font-size: 20px;
                            font-weight: 600;
                            color: #333; 
                            cursor: pointer;
                            border: 1px solid;
                            border-radius: 50%;
                            width: 29px;
                            height: 29px;
                            ">X</button>
      
            </div>`;


    const contentInfo = `
        <div style="background-color: #f7f7f7; color: #333; padding: 10px; border-radius: 10px; width: 300px;min-heght:150px">
     
           ${vehicleInfo}
           ${vehiclePhoto}            
           ${driverInfo}
           <button id="infoWindowButton" style="margin-top: 10px; padding: 5px 10px; color: #333; border: 1px solid; border-radius: 5px; cursor: pointer;">
          Detalhes do Veículo
        </button>
        </div>
    `;
    const containerInfoWindow = document.createElement("div");
    const rootInfoIndown = createRoot(containerInfoWindow);
    rootInfoIndown.render(<div dangerouslySetInnerHTML={{ __html: contentInfo }} />);

    const infoWindow = new google.maps.InfoWindow({
        headerDisabled: true,
        content: containerInfoWindow,
    });

    marker.addListener('click', () => {
        infoWindow.open(map, marker);

        setTimeout(() => {
            const button = document.getElementById('infoWindowButton');
            const buttonClose = document.getElementById('infoWindowClose');
            if (button) {
                button.addEventListener('click', () => {
                    onButtonClick(vehicle);
                    infoWindow.close();
                });
            }
            if (buttonClose) {
                buttonClose.addEventListener('click', () => {
                    infoWindow.close();
                });
            }
        }, 100);
    });

    return marker;
}

export default createAdvanceMarker;