import React, { useState } from 'react';
import styled from 'styled-components';
import { read, utils } from 'xlsx';
import { ctrlButtons, iMsgAlert } from '../../../interfaces/interfaces';
import { itemChips, itemChipsCheck, reqChip } from '../../../interfaces/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { changeAlert } from '../../../redux/alertServiceSlice';
import { alterContractChip, getListChipSimple, getListChips, saveChip, updateChip } from '../Gateways/chipsGateway';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import ChipCardList, { iChipsResume } from '../Components/ChipCardList';
import { SearchAndSelectContainer } from '../Styles/StyleChips';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import { useNotifications } from '../../../Notifications/NotificationContext';
import Sidebar from '../../../components/datagridComp/SideBar';
import SidebarImportChip from '../Components/SidebarImportChip';
import { iChipSave, iChipUpdate, iChipUpdate2 } from './ChipsCadForm';
import dayjs from 'dayjs';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerInfo, ContainerSearch, InfoBox } from '../../StylePages';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import { getColumnsChipsToDelete } from '../Utils/ExcelChipsColumnsToDelete';
import { getColumnsExcelChips } from '../Utils/ExcelChipsColumns';
import axios from 'axios';
import { calcularDiasComunicacao, FilterKeys, getStatusConn, getStatusContract } from '../Utils/tools';

interface iExcelData {
    id: number
    serial: string
    linha: string
    idfornecedor?: number
    operadora: string
    conexao: string
    consumo: string
}
interface iExcelFormat {
    id: number
    serial: string
    linha: string
    idfornecedor?: number
    operadora: string
    conexao: number
    consumo: string
}

const ContainerFlexInline = styled.div`
    display: flex;
    justify-content: center; 
    overflow: auto;   
`

const ContainerTable = styled.div`
padding: 6px;
    height: calc(100vh - 200px);
    overflow: auto;
`
const initialBtns: ctrlButtons = {
    RefreshShow: true,
    FilterShow: true,
    ChatShow: true
}

var reader = new FileReader();

let chipsExcel: iExcelFormat[] = []

export type lineChip = {
    id: number
    idempresa: number
    status: string
    statusFilter: string
    statusContract: string
    status_conn: string
    contract_status: string
    idproviderClient: number
    idproviderExcel: number
    serial_provider: string | null
    number_provider: string | null
    operadora_provider: string | null
    serial_registed?: string | null
    number_registed?: string | null
    operadora_registed?: string | null
    id_registed?: number | null
    update_number: boolean
    update_operadora: boolean
    update_provider: boolean
    franchise?: number
    payment?: number
    idapn?: number
    conexao: number
}
type lineChipToDel = {
    id: number
    serial: string
    number: string
    operadora: string
}

type iResumeImport = {
    totalExcel: number
    totalRegisted: number
    totalWithoutRegister: number
    totalRegisterNoInProvider: number
    totalAttLine: number
    totalErrProvider: number
    onlineChips: number
    offlineChips: number
    totalNeverConn: number
    totalToCancel: number
    totalToDelete: number
    totalActive: number
    totalInactive: number
}

type filterSelected = {
    allExcel: boolean
    allRegisted: boolean
    newChips: boolean
    chipsDelete: boolean
    registerError: boolean
    toUpdate: boolean
    offlineChips: boolean
    onlineChips: boolean
    neverConnChips: boolean
}


let resumeImport: iResumeImport = {
    totalExcel: 0,
    totalRegisted: 0,
    totalWithoutRegister: 0,
    totalRegisterNoInProvider: 0,
    totalAttLine: 0,
    totalErrProvider: 0,
    onlineChips: 0,
    offlineChips: 0,
    totalNeverConn: 0,
    totalToCancel: 0,
    totalToDelete: 0,
    totalActive: 0,
    totalInactive: 0
}



const REGISTER_CHIPS = 'REGISTER_CHIPS'
const UPDATE_CHIPS = 'UPDATE_CHIPS'
const SETDEL_CHIPS = 'SETDEL_CHIPS'
const SETCANCEL_CHIPS = 'SETCANCEL_CHIPS'


const optionsActions = [
    { value: REGISTER_CHIPS, label: 'Registrar chips' },
    { value: UPDATE_CHIPS, label: 'Atualizar chips' },
    { value: SETDEL_CHIPS, label: 'Marcar p/ Deletar chips' },
    { value: SETCANCEL_CHIPS, label: 'Marcar p/ Cancelar chips' }
];








const ChipsInportsPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = React.useState<File>()
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [dataCategoryResume, setDataCategoryResume] = React.useState<iChipsResume[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const [activeFilterKey, setActiveFilterKey] = useState<string | null>(null);


    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    React.useEffect(() => {
        if (isFilePicked) {
            handleSubmission();
        }
    }, [isFilePicked]);

    //sidebar
    const handleCloseSideBar = () => setSideContent(undefined);
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<lineChip[]>([]);
    const filteredRows = React.useMemo(() => {
        // Primeiro, aplica os filtros
        let filteredData = rows;

        if (activeFilterKey === FilterKeys.STATUS_CANCEL) {
            filteredData = filteredData.filter(row => row.statusContract === 'statusCancel');
        } else if (activeFilterKey === FilterKeys.STATUS_DELETE) {
            filteredData = filteredData.filter(row => row.statusContract === 'statusDelete');
        } else if (activeFilterKey === FilterKeys.STATUS_ACTIVE) {
            filteredData = filteredData.filter(row => row.statusContract === 'statusActive');
        } else if (activeFilterKey === FilterKeys.STATUS_INACTIVE) {
            filteredData = filteredData.filter(row => row.statusContract === 'statusInactive');
        } else if (activeFilterKey === FilterKeys.OFFLINE_CHIPS) {
            filteredData = filteredData.filter(row => row.status_conn === 'OFF-LINE');
        } else if (activeFilterKey === FilterKeys.ONLINE_CHIPS) {
            filteredData = filteredData.filter(row => row.status_conn === 'ON-LINE');
        } else if (activeFilterKey === FilterKeys.NEVER_CONN) {
            filteredData = filteredData.filter(row => row.status_conn === 'NUNCA CONECTOU');
        } else if (activeFilterKey) {
            filteredData = filteredData.filter(row => row.statusFilter === activeFilterKey);
        }

        // Em seguida, aplica a pesquisa
        if (findInputTerms) {
            filteredData = handleSearch(filteredData, findInputTerms);
        }

        return filteredData;
    }, [activeFilterKey, rows, findInputTerms, handleSearch]);

    const [rowsToDelete, setRowsToDelete] = React.useState<itemChips[]>([]);
    const [filteredRowsToDelete, setFilteredRowsToDelete] = React.useState<itemChips[]>(rowsToDelete);

    const handleSetMsgInfo = (alert: iMsgAlert) => {
        alert.open = true;
        dispatch(changeAlert(alert))
    }



    async function recalcCards() {
        const chipsResumeData: iChipsResume[] = [
            { category: "Total Excel", category_icon: "", total_amount: resumeImport.totalExcel ?? 0, isSelected: activeFilterKey === FilterKeys.ALL_EXCEL, filterKey: FilterKeys.ALL_EXCEL },
            { category: "Total Cadastrado", category_icon: "", total_amount: resumeImport.totalRegisted ?? 0, isSelected: activeFilterKey === FilterKeys.HAS_REGISTER, filterKey: FilterKeys.HAS_REGISTER },
            { category: "Chips Novos", category_icon: "", total_amount: resumeImport.totalWithoutRegister ?? 0, isSelected: activeFilterKey === FilterKeys.NEW_CHIPS, filterKey: FilterKeys.NEW_CHIPS },
            { category: "CANCELAR", category_icon: "", total_amount: resumeImport.totalToCancel, isSelected: activeFilterKey === FilterKeys.STATUS_CANCEL, filterKey: FilterKeys.STATUS_CANCEL },
            { category: "DELETAR", category_icon: "", total_amount: resumeImport.totalToDelete, isSelected: activeFilterKey === FilterKeys.STATUS_DELETE, filterKey: FilterKeys.STATUS_DELETE },
            { category: "ATIVOS", category_icon: "", total_amount: resumeImport.totalActive, isSelected: activeFilterKey === FilterKeys.STATUS_ACTIVE, filterKey: FilterKeys.STATUS_ACTIVE },
            { category: "INATIVOS", category_icon: "", total_amount: resumeImport.totalInactive, isSelected: activeFilterKey === FilterKeys.STATUS_INACTIVE, filterKey: FilterKeys.STATUS_INACTIVE },
            { category: "OFF-LINE", category_icon: "", total_amount: resumeImport.offlineChips ?? 0, isSelected: activeFilterKey === FilterKeys.OFFLINE_CHIPS, filterKey: FilterKeys.OFFLINE_CHIPS },
            { category: "ON-LINE", category_icon: "", total_amount: resumeImport.onlineChips ?? 0, isSelected: activeFilterKey === FilterKeys.ONLINE_CHIPS, filterKey: FilterKeys.ONLINE_CHIPS },
            { category: "NUNCA CONECTOU", category_icon: "", total_amount: resumeImport.totalNeverConn ?? 0, isSelected: activeFilterKey === FilterKeys.NEVER_CONN, filterKey: FilterKeys.NEVER_CONN },
            { category: "Chips A Deletar", category_icon: "", total_amount: resumeImport.totalRegisterNoInProvider ?? 0, isSelected: activeFilterKey === FilterKeys.CHIPS_DELETE, filterKey: FilterKeys.CHIPS_DELETE },
            { category: "Fornecedor registrado errado", category_icon: "", total_amount: resumeImport.totalErrProvider ?? 0, isSelected: activeFilterKey === FilterKeys.REGISTER_ERROR, filterKey: FilterKeys.REGISTER_ERROR },
            { category: "Atualizar Linha", category_icon: "", total_amount: resumeImport.totalAttLine ?? 0, isSelected: activeFilterKey === FilterKeys.TO_UPDATE, filterKey: FilterKeys.TO_UPDATE },
        ];
        setDataCategoryResume(chipsResumeData);
    }



    const processItens = (chips: itemChipsCheck[]) => {
        try {
            //console.log(chips)
            //console.log(chipsExcel)
            let rowsLine: lineChip[] = []
            resumeImport = {
                totalExcel: 0,
                totalRegisted: 0,
                totalWithoutRegister: 0,
                totalRegisterNoInProvider: 0,
                totalAttLine: 0,
                totalErrProvider: 0,
                onlineChips: 0,
                offlineChips: 0,
                totalNeverConn: 0,
                totalToCancel: 0,
                totalToDelete: 0,
                totalActive: 0,
                totalInactive: 0
            }
            resumeImport.totalExcel = chipsExcel.length
            resumeImport.totalRegisted = chips.length
            for (const item of chipsExcel) {
                let [chip] = chips.filter(i => i.serial === item.serial)
                let status = 'NOVO'
                let statusFilter = ''
                let statusConn = getStatusConn(item.conexao)
                statusFilter = FilterKeys.NEW_CHIPS
                if (chip !== undefined) {//chip cadastrado
                    status = 'CADASTRADO'
                    statusFilter = FilterKeys.HAS_REGISTER
                    let statusContract = getStatusContract(chip.contract_status ?? '')
                    let isUpdateProvider = item.idfornecedor !== chip.idprovider
                    let isUpdateLine = item.linha !== chip.linha
                    let isUpdateOperadora = item.operadora !== chip.operadora
                    if (isUpdateProvider) {
                        status = 'FORNECEDOR ERRADO'
                        statusFilter = FilterKeys.REGISTER_ERROR
                    }
                    if (!isUpdateProvider && (isUpdateLine || isUpdateOperadora)) {
                        status = 'ATUALIZAR LINHA'
                        statusFilter = FilterKeys.TO_UPDATE
                    }
                    let line: lineChip = {
                        id: item.id,
                        idempresa: chip.idempresa,
                        status,
                        statusFilter,
                        status_conn: statusConn,
                        statusContract: statusContract,
                        contract_status: chip.contract_status ?? '',
                        idproviderClient: chip.idprovider ?? 0,
                        idproviderExcel: item.idfornecedor ?? 0,
                        serial_provider: item.serial,
                        number_provider: item.linha,
                        operadora_provider: item.operadora,
                        serial_registed: chip.serial,
                        number_registed: chip.linha,
                        operadora_registed: chip.operadora,
                        id_registed: chip.id,
                        update_number: isUpdateLine,
                        update_operadora: isUpdateOperadora,
                        update_provider: isUpdateProvider,
                        conexao: item.conexao ?? 0
                    }
                    if (line.update_number) resumeImport.totalAttLine++
                    if (line.update_provider) resumeImport.totalErrProvider++
                    rowsLine.push(line)
                    //console.log(line, chip, item)
                } else {
                    resumeImport.totalWithoutRegister += 1
                    let line: lineChip = {
                        id: item.id,
                        idempresa: 0,
                        status,
                        statusFilter,
                        status_conn: statusConn,
                        statusContract: 'statusActive',
                        contract_status: 'NOVO',
                        idproviderClient: 0,
                        idproviderExcel: item.idfornecedor ?? 0,
                        serial_provider: item.serial,
                        number_provider: item.linha,
                        operadora_provider: item.operadora,
                        id_registed: 0,
                        update_number: true,
                        update_operadora: true,
                        update_provider: true,
                        conexao: item.conexao ?? '0'
                    }
                    rowsLine.push(line)
                }
                if (item.conexao === -1) resumeImport.totalNeverConn++
                if (item.conexao >= 0 && item.conexao <= 30) resumeImport.onlineChips++
                if (item.conexao >= 30) resumeImport.offlineChips++
            }
            for (const chip of rowsLine) {
                if (chip.contract_status === 'DELETAR') {
                    resumeImport.totalToDelete += 1
                }
                if (chip.contract_status === 'CANCELAR') {
                    resumeImport.totalToCancel += 1
                }
                if (chip.contract_status === 'ATIVO') {
                    resumeImport.totalActive += 1
                }
                if (chip.contract_status === 'INATIVO') {
                    resumeImport.totalInactive += 1
                }
            }
            for (const chip of chips) {
                let [itemExcel] = chipsExcel.filter(i => i.serial === chip.serial)
                if (itemExcel === undefined && chip.contract_status !== 'DELETAR') {
                    resumeImport.totalRegisterNoInProvider += 1
                }
            }
            setRows(rowsLine)
            recalcCards()
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[processItens]', error)
        }
    }



    async function reqListChipsSimple() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                idEmpresas: [49, 20250087, 20250098]
            }
            const result = await getListChipSimple(payload);
            let chips = result.map(item => {
                let out: itemChipsCheck = {
                    id: item.id,
                    idempresa: item.idempresa,
                    serial: item.serial ? item.serial.replace(/\D/g, "") : '',
                    linha: item.linha ? item.linha.replace(/\D/g, "") : '',
                    operadora: item.operadora ? item.operadora.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : '',
                    idprovider: item.idprovider,
                    contract_status: item.contract_status,
                }
                return out
            })
            processItens(chips)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[reqListChipsSimple]', error)
        }
    }
    async function reqListChipsToAnalise() {
        try {
            setIsLoading(true)
            const payload: reqChip = {
                nDaysOffline: 30,
                idempresa: user.idempresa,
                idEmpresas: [49, 20250087, 20250098],
            }
            const result = await getListChips(payload);
            if (result) {
                let rowsToDel: itemChips[] = []
                for (const chip of result) {
                    let serial = chip.serial ? chip.serial.replace(/\D/g, "") : ''
                    let [itemExcel] = chipsExcel.filter(i => i.serial === serial)
                    if (itemExcel === undefined && chip.contract_status !== 'DELETAR') {
                        rowsToDel.push(chip)
                    }
                }
                setRowsToDelete(rowsToDel)
                setFilteredRowsToDelete(rowsToDel)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[reqListChipsSimple]', error)
        }
    }



    const handleFilter = (filterKey: string) => {
        deselectAll()
        setActiveFilterKey(prevKey => (prevKey === filterKey ? null : filterKey));
        if (activeFilterKey === FilterKeys.CHIPS_DELETE) {
            reqListChipsToAnalise()
        }
    }

    React.useEffect(() => {
        recalcCards()
    }, [activeFilterKey]);

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }


    const handleSubmission = async () => {
        try {
            setIsLoading(true)
            reader.onload = function (e) {
                var f = e.target?.result;
                const wb = read(f, { type: 'binary' });
                const data = utils.sheet_to_json<iExcelData>(wb.Sheets[wb.SheetNames[0]]);
                let newdata = data.map((item, i) => {
                    let out: iExcelFormat = {
                        id: i,
                        serial: item.serial ? item.serial.replace(/\D/g, "") : '',
                        linha: item.linha ? item.linha.replace(/\D/g, "").substring(2) : '',
                        operadora: item.operadora ? item.operadora.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : '',
                        idfornecedor: item.idfornecedor ? item.idfornecedor : 0,
                        conexao: (item.conexao && item.conexao.length > 0) ? calcularDiasComunicacao(item.conexao.replace(/['"]/g, '')) : -1,
                        consumo: ''
                    }
                    return out

                })
                chipsExcel = newdata
                reqListChipsSimple()
                setIsLoading(false)

            }

            reader.readAsArrayBuffer(selectedFile as unknown as Blob);
        } catch (e) {
            setIsLoading(false)
            let error = e as Error
            handleSetMsgInfo({ msg: error.message, type: 'error' })
            console.log('[ReadExcel]', error)
        }
    }

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }
        switch (action) {
            case REGISTER_CHIPS:
                setSideContent(REGISTER_CHIPS)
                break
            case UPDATE_CHIPS:
                setSideContent(UPDATE_CHIPS)
                break
            case SETDEL_CHIPS:
                setSideContent(SETDEL_CHIPS)
                break
            case SETCANCEL_CHIPS:
                setSideContent(SETCANCEL_CHIPS)
                break
        }
    };

    const handleCloseSidebar = () => {
        setSideContent(undefined);
    };

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((Expense) => Expense.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleRegisterChips = async (value: any) => {
        try {
            let idprovider = value.provider.id_provider
            let idapn = value.provider.id
            let franchise = value.franchise ?? 0
            let payment = value.payment ?? 0
            let operator = value.provider.Operadora ?? ''
            if (idprovider === 0 || idapn === 0) {
                addNotification('', 'Fornecedor não selecionado', 'warning');
                return;
            }
            let itensSave: iChipSave[] = []
            selectedRows.forEach((id) => {
                let chip = rows.find(i => i.id === id)
                if (chip === undefined) return
                if (chip.status !== 'NOVO') return
                let input: iChipSave = {
                    serial: chip.serial_provider ?? '',
                    line: chip.number_provider ?? '',
                    franchise: franchise,
                    payment: payment,
                    idapn: idapn,
                    idprovider: idprovider,
                    operator: operator
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await saveChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Cadastro de chips', `${itensSave.length} Chips Cadastrados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }

    const handleUpdateChips = async (value: any) => {
        try {

            let itensSave: iChipUpdate[] = []
            let idprovider = value.provider.id_provider
            let idapn = value.provider.id
            let franchise = value.franchise ?? 0
            let payment = value.payment ?? 0
            let operator = value.provider.Operadora ?? ''
            if (idprovider === 0 || idapn === 0) {
                addNotification('', 'Fornecedor não selecionado', 'warning');
                return;
            }
            selectedRows.forEach((id) => {
                let chip = rows.find(i => i.id === id)
                if (chip === undefined) return
                if (chip.status === 'NOVO' || chip.status === 'CADASTRADO') return
                let input: iChipUpdate = {
                    id: chip.id_registed ?? 0,
                    serial: chip.serial_provider ?? '',
                    line: chip.number_provider ?? '',
                    franchise: franchise,
                    payment: payment,
                    idapn: idapn,
                    idprovider: idprovider,
                    operator: operator,
                    reason: 'atualização de chips via excel'
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await updateChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Atualização de chips', `${itensSave.length} Chips Atualizados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }

    const handleDeleteChips = async () => {
        try {

            let itensSave: iChipUpdate2[] = []
            selectedRows.forEach((id) => {
                let chip = rowsToDelete.find(i => i.id === id)
                if (chip === undefined) return
                let input: iChipUpdate2 = {
                    id: chip.id ?? 0,
                    contract_status: 'DELETAR'
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await alterContractChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Alterar Status de chips', `${itensSave.length} Chips Atualizados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }

    const handleCancelChips = async () => {
        try {

            let itensSave: iChipUpdate2[] = []
            selectedRows.forEach((id) => {
                let chip = rows.find(i => i.id === id)
                if (chip === undefined) return
                if (chip.status === 'NOVO') return
                let input: iChipUpdate2 = {
                    id: chip.id_registed ?? 0,
                    contract_status: 'CANCELAR'
                }
                itensSave.push(input)
            })
            for (let x = 0; x < itensSave.length; x++) {
                let resp = await alterContractChip(itensSave[x])
            }
            handleCloseSidebar()
            reqListChipsSimple()
            deselectAll()
            addNotification('Alterar Status de chips', `${itensSave.length} Chips Atualizados com sucesso`, 'success');

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
            console.log('[handleRegisterChips]', error)
        }

    }


    const columns = getColumnsExcelChips()
    const columnsToDelete = getColumnsChipsToDelete()

    return (
        <>
            <ContainerFlexInline>
                <input type="file" name="file" onChange={changeHandler} />
            </ContainerFlexInline>
            <ChipCardList categories={dataCategoryResume} onClick={handleFilter} />
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <ActionSelect
                    options={optionsActions}
                    onSelect={handleSelectAction}
                    title='Ação em Massa'
                />
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={handleSubmission}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Recarregar Excel
                </BtnFaIcon >
            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>


            <ContainerTable>
                {activeFilterKey === FilterKeys.CHIPS_DELETE ?
                    <DataGridComp
                        rows={rowsToDelete}
                        columns={columnsToDelete}
                        selectedRows={selectedRows}
                        onRowSelect={handleRowSelect}
                    /> :
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowSelect={handleRowSelect}
                    />}
            </ContainerTable>

            <Sidebar isOpen={sideContent === REGISTER_CHIPS} onClose={handleCloseSidebar} title={'Cadastrar Chips'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === REGISTER_CHIPS}
                    type={sideContent ?? ''}
                    message="Cadastrar chips selecionados!"
                    onConfirm={handleRegisterChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === UPDATE_CHIPS} onClose={handleCloseSidebar} title={'Atualizar Chips'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === UPDATE_CHIPS}
                    type={sideContent ?? ''}
                    message="Atualizar chips selecionados!"
                    onConfirm={handleUpdateChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === SETDEL_CHIPS} onClose={handleCloseSidebar} title={'Status á deletar'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === SETDEL_CHIPS}
                    type={sideContent ?? ''}
                    message="Marcar chips para deletar! esse procedimento não deleta os chips, apenas marca para deletar"
                    onConfirm={handleDeleteChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
            <Sidebar isOpen={sideContent === SETCANCEL_CHIPS} onClose={handleCloseSidebar} title={'Status á cancelar'}>
                <SidebarImportChip
                    requireJustification={false}
                    isShow={sideContent === SETCANCEL_CHIPS}
                    type={sideContent ?? ''}
                    message="Marcar chips para Cancelar contrato!"
                    onConfirm={handleCancelChips}
                    onCancel={() => handleCloseSideBar()}
                /> </Sidebar>
        </>
    )
}

export default ChipsInportsPage


