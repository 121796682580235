



import React, { useEffect } from 'react';
import { BoxIcon, BtnFaIcon, ContainerInfo, ContainerSearch, ContainerSpan, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { faRefresh, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import { blockUsersAlter, deleteUsers, getListUsers, iUsersData, iUsersSystem } from '../Repository/UserRepo';
import NoData from '../../../components/NoData';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { getColumnsUsers } from '../Utils/UserColumns';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormUsers from '../Forms/FormUsers';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ListVehiclesInUser from '../../Vehicles/Views/ListVehiclesInUser';
import { v4 as uuidv4 } from 'uuid';
import { AuthConfig } from '../../../Config/AuthContext';
import ActionSelect from '../../../components/datagridComp/ActionSelect';
import ModalComp from '../../../components/datagridComp/ModalComp';
import ConfirmationDialog from '../../../components/datagridComp/ConfirmationDialog';

const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';
const OPEN_VEHICLE_INUSER = 'OPEN_VEHICLE_INUSER';
const OPEN_FORM_USER = 'OPEN_FORM_USER';


interface IOption {
    value: string;
    label: string;
}


const optionsUserFilter: IOption[] = [
    { value: '0', label: 'Todos os Usuários' },
    { value: '2', label: 'Usuários do Sistema' },
    { value: '3', label: 'Usuários de Clientes' },
];

const optionsActions = [
    { value: 'SETBLOCK', label: 'Bloquear Usuários' },
    { value: 'SETUNBLOCK', label: 'Liberar Usuários' },
    { value: 'DELETEUSER', label: 'Apagar Usuários' },

];

interface iAction {
    ids: number[];
    title: string;
    msg: string;
    action: string;
    value: any;
}

const ListUsers: React.FC = () => {

    const { user } = AuthConfig();
    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<iUsersData[]>([]);
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');
    const { addNotification } = useNotifications();
    const [ItemEdit, setItemEdit] = React.useState<iUsersSystem>();
    const [filterUser, setFilterUser] = React.useState<number>(0);
    const formRef = React.useRef<FormHandles>(null)
    const [itemAction, setItemAction] = React.useState<iAction>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();

    const handleEditItem = (row: iUsersSystem) => {
        setItemEdit(row);
        setSideContent(EDIT_FORM);
    };

    const handleOpenListVehicles = (row: iUsersSystem) => {
        setItemEdit(row);
        setSideContent(OPEN_VEHICLE_INUSER);
    };

    const columns = getColumnsUsers(user?.nivel ?? 3, handleEditItem, handleOpenListVehicles);

    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);

    const getListInServer = async () => {
        try {
            setMessage('Carregando dados...');
            const result = await getListUsers(filterUser);
            setRows(result);
            const selected: Set<number> = new Set(
                result.filter((row: { selected: boolean }) => row.selected).map((row: { id: number }) => row.id)
            ); setSelectedRows(selected);
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    };


    useEffect(() => {
        getListInServer();
    }, [filterUser]);

    const handleAdd = async () => {
        setItemEdit(undefined);
        setSideContent(NEW_FORM);

    }

    const handleUpdateList = async () => {
        setSideContent(undefined);
        setItemEdit(undefined);
        getListInServer();
    }

    const CancelForm = () => {
        setSideContent(undefined);
        setItemEdit(undefined);
    }

    const handleChangedSelected = async (rule: number) => {
        setFilterUser(rule);
        getListInServer();
    }

    const handleSelectAction = (action: string) => {
        if (selectedRows.size === 0) {
            addNotification('', 'Precisa selecionar ao menos um registro', 'warning');
            return;
        }

        switch (action) {
            case 'SETBLOCK':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Bloquear Usuários',
                    msg: 'Deseja bloquear os usuários selecionados?',
                    action: 'SETBLOCK',
                    value: 0
                });
                break;
            case 'SETUNBLOCK':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Liberar Usuários',
                    msg: 'Deseja liberar os usuários selecionados?',
                    action: 'SETUNBLOCK',
                    value: 1
                });
                break;
            case 'DELETEUSER':
                setItemAction({
                    ids: Array.from(selectedRows),
                    title: 'Apagar Usuários',
                    msg: 'Deseja apagar os usuários selecionados?',
                    action: 'DELETEUSER',
                    value: 0
                });
                break;
            default:
                break;
        }

    };


    const sendActionBlock = async (action: iAction) => {
        try {
            setIsLoading(true);
            await blockUsersAlter(action.ids, action.value);
            addNotification('', 'Ação realizada com sucesso', 'success');
            getListInServer();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const sendActionDelete = async (action: iAction) => {
        try {
            setIsLoading(true);
            await deleteUsers(action.ids);
            addNotification('', 'Ação realizada com sucesso', 'success');
            getListInServer();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleConfirmedAction = () => {
        setItemAction(undefined);
        if (itemAction === undefined) return;
        switch (itemAction.action) {
            case 'SETBLOCK':
            case 'SETUNBLOCK':
                sendActionBlock(itemAction);
                break;
            case 'DELETEUSER':
                sendActionDelete(itemAction);
                break;
            default:
                break;
        }

    }

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <>
                    <SearchAndSelectContainer>
                        <ActionSelect
                            options={optionsActions}
                            onSelect={handleSelectAction}
                            title='Selecione a ação'
                        />
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        {user?.nivel !== 3 && <div>
                            <Form placeholder={""} ref={formRef} onSubmit={console.log} noValidate={true} initialData={undefined}>
                                <SelectFieldForm
                                    options={optionsUserFilter}
                                    value={filterUser}
                                    name={'filterUser'}
                                    onChange={(e) => handleChangedSelected(Number(e.target.value) ?? 0)}
                                /></Form>
                        </div>}
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkgreen" title='Adicionar Usuário' onClick={handleAdd} >
                            <BoxIcon icon={faUserAlt} className="fa-icon" /> Adicionar
                        </BtnFaIcon >
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </>
            }
            <Sidebar width='600px' isOpen={sideContent === NEW_FORM} onClose={CancelForm} title={'Cadastro de Usuário'}>
                <FormUsers
                    onUpdate={handleUpdateList}
                    onCancel={CancelForm}
                    uuid={uuidv4()}
                />
            </Sidebar>
            {ItemEdit && <Sidebar width='700px' isOpen={sideContent === EDIT_FORM} onClose={CancelForm} title={'Editar Usuário'}>
                <FormUsers
                    onCancel={CancelForm}
                    onUpdate={handleUpdateList}
                    initialData={ItemEdit}
                    uuid={uuidv4()}
                />
            </Sidebar>}

            {ItemEdit && <Sidebar width='800px' isOpen={sideContent === OPEN_VEHICLE_INUSER} onClose={CancelForm} title={'Lista de Veículos'}>
                <ListVehiclesInUser
                    idUser={ItemEdit?.id ?? 0}
                    idgroup={ItemEdit?.idgroup ?? 0}
                    rule={Number(ItemEdit?.rule ?? 0)}
                    onUpdated={handleUpdateList} />
            </Sidebar>}

            {itemAction && <ModalComp title={itemAction.title} subtitle="" onClose={() => setItemAction(undefined)}>
                <ConfirmationDialog
                    type={'QUESTION'}
                    requireJustification={false}
                    onCancel={() => setItemAction(undefined)}
                    onConfirm={handleConfirmedAction}
                    message={itemAction.msg} />
            </ModalComp>}
            {isLoading && <ModalComp title={""} subtitle="" onClose={() => setItemAction(undefined)}>
                <LoadingIndicator message={"Processando..."} />
            </ModalComp>}

        </>
    )

}
export default ListUsers