import { itemChips } from '../../../interfaces/types';

import { FormHandles } from '@unform/core';

import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { BackButton, Button, CloseButton, Container, ErrorMessage, Input, InputContainer, Label, MessageErrorContainer, PageContainer, Row, SaveButton, SpinnerStyled, Title } from '../ChipsStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ChipProvidersSelect, { IChipProvider } from '../Components/ChipProvidersSelect';
import SmallButton from '../../../components/datagridComp/SmallButton';
import ModalComp from '../../../components/datagridComp/ModalComp';
import ProviderCardInfo, { iProviderData } from '../Components/ProviderCardInfo';
import { getInfoProvider, saveChip, updateChip } from '../Gateways/chipsGateway';
import ConfirmationDialog from '../../../components/datagridComp/ConfirmationDialog';


interface FormErrors {
    serial?: string
    line?: string
    franchise?: string
    payment?: string
    provider?: string
}

export interface iChipSave {
    serial: string
    line: string
    franchise: number
    payment: number
    idapn: number
    idprovider: number
    operator: string
}

export interface iChipUpdate extends iChipSave {
    id: number
    reason: string,
    contract_status?: string
}
export interface iChipUpdateExcel {
    id: number
    reason: string
    line: string
}

export interface iChipUpdate2 {
    id: number
    reason?: string,
    contract_status?: string
    serial?: string
    line?: string
    franchise?: number
    payment?: number
    idapn?: number
    idprovider?: number
    operator?: string
}

export interface RegisterFormState {
    serial: string
    line?: string
    franchise: string
    payment: string
}

interface CompProps {
    onSaveClick: () => void
    onCancelClick: () => void
    initialData?: itemChips
}

const PROVIDERS_CONTENT = 'providers'
const CONFIRMED_CONTENT = 'confirmed'
const CONFIRM_ALTECHIP = 'confirm_alter_chip'

const ChipsCadForm: React.FC<CompProps> = ({ onCancelClick, onSaveClick, initialData }) => {


    const dispatch = useDispatch()
    const formRef = useRef<FormHandles>(null)
    const { user } = useSelector(selectUser);

    const [errors, setErrors] = React.useState<FormErrors>({});
    const [errorMsg, SetErrorMsg] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [providerInfo, setProviderInfo] = React.useState<iProviderData>();
    const [formState, setFormState] = React.useState<RegisterFormState>({
        serial: "",
        line: "",
        franchise: "",
        payment: ""
    });
    //modal
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const [modalContent, setModalContent] = React.useState<string>('');


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };
    const handleShowProviders = () => {
        setModalContent(PROVIDERS_CONTENT)
        setIsModalOpen(true)
    };
    const handleSubmitManager = () => {
        if (initialData?.id) {
            let isValid = validateForm()
            if (isValid) {
                setModalContent(CONFIRM_ALTECHIP)
                setIsModalOpen(true)
            }
        } else {
            handleSubmit()
        }
    };



    const validateForm = () => {
        let newErrors: FormErrors = {};
        console.log(formState)

        if (formState.serial == null || formState.serial === '') {
            newErrors.serial = "O Serial é obrigatório.";
        }

        // Validar franchise: não vazio
        if (formState.franchise == null || formState.franchise === '') {
            newErrors.franchise = "A Franquia é obrigatória.";
        }

        // Validar payment: não vazio
        if (formState.payment == null || formState.payment === '') {
            newErrors.payment = "Obrigatório.";
        }

        if (!providerInfo) {
            newErrors.provider = "Selecione um Fornecedor/APN.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {

        try {
            setIsLoading(true)
            let isValid = validateForm()
            if (isValid) {
                let input: iChipSave = {
                    serial: formState.serial,
                    line: formState.line ?? '',
                    franchise: Number(formState.franchise ?? 0),
                    payment: Number(formState.payment ?? 0),
                    idapn: providerInfo?.id ?? 0,
                    idprovider: providerInfo?.id_provider ?? 0,
                    operator: providerInfo?.Operadora ?? ''
                }
                await saveChip(input)
                setFormState({
                    serial: "",
                    line: "",
                    franchise: "",
                    payment: ""
                })
                setModalContent(CONFIRMED_CONTENT)
                setIsModalOpen(true)
            }
        } catch (error) {
            let message =
                "Erro ao tentar conectar com servidor. Por favor, tente novamente.";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message || message;
            } else if (error instanceof Error) {
                message = error.message;
            }
            SetErrorMsg(message)
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitAlter = async (reason?: string) => {
        try {
            handleClose()
            setIsLoading(true)
            let input: iChipUpdate = {
                id: initialData?.id ?? 0,
                serial: formState.serial,
                line: formState.line ?? '',
                franchise: Number(formState.franchise ?? 0),
                payment: Number(formState.payment ?? 0),
                idapn: providerInfo?.id ?? 0,
                idprovider: providerInfo?.id_provider ?? 0,
                reason: reason ?? '',
                operator: providerInfo?.Operadora ?? ''
            }
            await updateChip(input)
            setFormState({
                serial: "",
                line: "",
                franchise: "",
                payment: ""
            })
            setModalContent(CONFIRMED_CONTENT)
            setIsModalOpen(true)

        } catch (error) {
            let message =
                "Erro ao tentar conectar com servidor. Por favor, tente novamente.";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message || message;
            } else if (error instanceof Error) {
                message = error.message;
            }
            SetErrorMsg(message)
        } finally {
            setIsLoading(false);
        }
    };


    const handleAPNSelected = async (provider: IChipProvider) => {
        handleClose();
        setProviderInfo(provider)
    };

    async function getProviderApnInfo(idapn: number) {
        try {
            setIsLoading(true)
            const result = await getInfoProvider(idapn);
            if (result) {
                console.log(result)
                setProviderInfo(result)
            }
        } catch (error) {
            let message =
                "Erro ao tentar conectar com servidor. Por favor, tente novamente.";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message || message;
            } else if (error instanceof Error) {
                message = error.message;
            }
            SetErrorMsg(message)
        } finally {
            setIsLoading(false);
        }
    }


    React.useEffect(() => {
        console.log(initialData)
        if (initialData) {
            setFormState({
                serial: initialData.serial.replace(/\D/g, ''),
                line: initialData.linha.replace(/\D/g, ''),
                franchise: initialData.franchise,
                payment: initialData.payment,
            });
            getProviderApnInfo(initialData.idapn)
        }
    }, [initialData]);

    return (
        <>
            <PageContainer>
                {errorMsg && (
                    <MessageErrorContainer>
                        {errorMsg}
                        <CloseButton onClick={() => SetErrorMsg('')}>✖</CloseButton>
                    </MessageErrorContainer>
                )}
                {isModalOpen && (
                    <ModalComp title="" subtitle="" onClose={handleClose}>
                        {modalContent === PROVIDERS_CONTENT && <ChipProvidersSelect onRowSelect={handleAPNSelected} />}
                        {modalContent === CONFIRMED_CONTENT && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => onSaveClick()} onCancel={() => handleClose()} />}
                        {modalContent === CONFIRM_ALTECHIP && <ConfirmationDialog type='QUESTION' requireJustification={true} message="Informe o motivo da alteração no cadastro do chip!" onConfirm={handleSubmitAlter} onCancel={() => handleClose()} />}

                    </ModalComp>
                )}
                <Container>
                    <Title>Cadastro de Chips</Title>
                    <InputContainer>
                        <Label>Serial(ICCID)*</Label>
                        <Input
                            name="serial"
                            value={formState.serial}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Serial do chip"
                            type="number"
                        />
                        {errors.serial && (
                            <ErrorMessage>
                                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.serial}
                            </ErrorMessage>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <Label>Número da Linha</Label>
                        <Input
                            name="line"
                            value={formState.line}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Número da Linha"
                            type="number"
                        />
                        {errors.line && (
                            <ErrorMessage>
                                <FontAwesomeIcon icon={faExclamationCircle} /> {errors.line}
                            </ErrorMessage>
                        )}
                    </InputContainer>
                    <Row>
                        <InputContainer>
                            <Label>Franquia*</Label>
                            <Input
                                name="franchise"
                                value={formState.franchise}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Franquia de dados"
                                type="number"
                            />
                            {errors.franchise && (
                                <ErrorMessage>
                                    <FontAwesomeIcon icon={faExclamationCircle} /> {errors.franchise}
                                </ErrorMessage>
                            )}
                        </InputContainer>
                        <InputContainer>
                            <Label>Mensalidade*</Label>
                            <Input
                                name="payment"
                                value={formState.payment}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Mensalidade"
                                type="number"
                            />
                            {errors.payment && (
                                <ErrorMessage>
                                    <FontAwesomeIcon icon={faExclamationCircle} /> {errors.payment}
                                </ErrorMessage>
                            )}
                        </InputContainer>
                    </Row>
                    <SmallButton label='Selecionar Fornecedor / APN' onClick={handleShowProviders} />
                    {errors.provider && (
                        <ErrorMessage>
                            <FontAwesomeIcon icon={faExclamationCircle} /> {errors.provider}
                        </ErrorMessage>
                    )}
                    {providerInfo && <ProviderCardInfo data={providerInfo} />}
                    <Row>
                        <BackButton onClick={() => onCancelClick()}  >Cancelar</BackButton>
                        <SaveButton onClick={handleSubmitManager} disabled={isLoading}>
                            {isLoading ? (
                                <SpinnerStyled role="status" aria-hidden="true"></SpinnerStyled>
                            ) : (
                                "Salvar"
                            )}
                        </SaveButton>
                    </Row>
                </Container>
            </PageContainer>
        </>
    )

}
export default ChipsCadForm


