import { Column, SelectColumn } from 'react-data-grid';
import { iUsersData, iUsersSystem } from '../Repository/UserRepo';
import { FaQuestion, FaUser, FaUserShield } from 'react-icons/fa';
import VehicleCell from '../Components/VehicleCell';
import { BtnCheckBox } from '../../StylePages';
import Tooltip from '../../../components/datagridComp/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faClock, faCheckCircle } from '@fortawesome/free-solid-svg-icons'; // Ícones usados


export const getColumnsUsers = (
    userLevel: number,
    editItem: (row: iUsersSystem) => void,
    openListVehicles: (row: iUsersSystem) => void
): Column<iUsersData>[] => {
    const columns: Column<iUsersData>[] = [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        {
            key: 'active',
            name: 'Info',
            width: 50,
            renderCell(props: any) {
                const { active, expire_at } = props.row;


                // Verifica se já expirou
                const isExpired = expire_at && new Date(expire_at) < new Date();

                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {/* Cadeado Bloqueado */}
                        {(active === 0 || isExpired) && (
                            <Tooltip text="Usuário bloqueado">
                                <FontAwesomeIcon icon={faLock} style={{ color: 'gray' }} />
                            </Tooltip>
                        )}

                        {/* Status OK */}
                        {(active === 1 && !isExpired) && (
                            <Tooltip text="Status OK">
                                <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                            </Tooltip>
                        )}

                        {/* Data de Expiração */}
                        {expire_at && (
                            <Tooltip
                                text={
                                    isExpired
                                        ? `Expirado em: ${new Date(expire_at).toLocaleDateString()}`
                                        : `Expira em: ${new Date(expire_at).toLocaleDateString()}`
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    style={{ color: isExpired ? 'red' : 'gray' }}
                                />
                            </Tooltip>
                        )}



                    </div>
                );
            },
        },
        // Coluna para ações
        {
            key: 'edit',
            name: 'Ações',
            width: 90,
            renderCell(props: any) {
                return (
                    <>{/*<BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>*/}
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },
        {
            key: 'vehicle_count',
            name: 'Acessos',
            width: 85,
            renderCell(props: any) {
                return (
                    <VehicleCell
                        idgroup={props.row.idgroup}
                        rule={props.row.rule}
                        vehicle_count={props.row.vehicle_count}
                        onClick={() => openListVehicles(props.row)}
                    />
                );
            },
        },

        // Coluna para ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para nome
        {
            key: 'name',
            name: 'Nome do Usuário',
            width: 200,
            resizable: true,
        },
        // Coluna para login
        {
            key: 'login',
            name: 'Login',
            width: 200,
            resizable: true,
        },
        // {
        //     key: 'idgroup',
        //     name: 'Grupo',
        //     width: 70,
        //     resizable: true,
        // },



    ];

    // Condicional para incluir a coluna "Cliente"
    if (userLevel !== 3) {
        columns.splice(5, 0, {
            key: 'client_name',
            name: 'Cliente',
            width: 200,
            resizable: true,
        });
        columns.splice(-1, 0, {
            key: 'rule',
            name: 'Tipo',
            width: 90,
            renderCell(props: any) {
                const rule = props.row.rule; // Obtem o valor de rule

                // Determina o texto e o ícone baseados na regra
                const ruleInfo = rule === 3
                    ? { label: 'Cliente', icon: <FaUser style={{ color: 'gray', marginRight: 5 }} /> }
                    : rule === 2
                        ? { label: 'Admin', icon: <FaUserShield style={{ color: 'green', marginRight: 5 }} /> }
                        : { label: 'Desconhecido', icon: <FaQuestion style={{ color: 'gray', marginRight: 5 }} /> }; // Caso padrão

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {ruleInfo.icon} {/* Ícone correspondente */}
                        {ruleInfo.label} {/* Texto correspondente */}
                    </div>
                );
            },
        });
    }

    return columns;
};

