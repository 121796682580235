import { Column, SelectColumn } from 'react-data-grid';
import { itemChips } from '../../../interfaces/types';
import ChipStatusContract from '../Components/ChipStatusContract';
import StatusChip from '../Components/ChipStatus';
import ChipStatusEquip from '../Components/ChipStatusEquip';

export const getColumnsChipsToDelete = (): Column<itemChips>[] => {
    return [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'idempresa',
            name: 'Empresa',
            width: 80
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 110,
            renderCell: (props: any) => (
                <ChipStatusContract status={props.row.contract_status} />
            )
        },
        {
            key: 'status_conn',
            name: 'Status',
            width: 100,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status_conn} />
            )
        },
        {
            key: 'equip_status',
            name: 'Equip',
            width: 100,
            renderCell: (props: any) => (
                <ChipStatusEquip status={props.row.equip_status} />
            )
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 150,
        },
        {
            key: 'serial',
            name: 'Serial',
            width: 230,
        },
        {
            key: 'nDiasConn',
            name: 'Conectado',
            width: 100
        },
        {
            key: 'equip_imei',
            name: 'Imei',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.equip_imei ? <>
                        <div><i>{props.row.equip_imei}</i> / <strong>{props.row.fab_nome}-{props.row.mod_ref}</strong></div>
                    </> : <></>
                )
            }
        },
        {
            key: 'cliente_name',
            name: 'Cliente',
            width: 240
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 130
        },


    ]
}