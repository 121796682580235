import React from 'react';
import ConfirmationDialog, { typeMethods } from '../../components/datagridComp/ConfirmationDialog';
import { DeleteAlert, getAlerts, getRulesAlerts, itemRuleAlert, RegisterAlertsInServer } from './repository/AlertsRepo';
import { Column, SelectColumn } from 'react-data-grid';
import ModalComp from '../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../components/datagridComp/LoadingComp';
import DataGridComp from '../../components/datagridComp/DataGridComp';
import ButtonEdit from '../../components/datagridComp/ButtonEdit';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerBarTop, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, PriorityItem, SearchAndSelectContainer } from '../StylePages';
import SearchField from '../../components/datagridComp/SearchField';
import ActionSelect from '../../components/datagridComp/ActionSelect';
import ColumnSelect from '../../components/datagridComp/ColumnSelect';
import ButtonRefresh from '../../components/datagridComp/ButtonRefresh';
import ButtonAdd from '../../components/datagridComp/ButtonAdd';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBell, faCar, faFlag, faRefresh, faStop, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import FormAlertsCreate from './View/FormAlertsCreate';
import ModalListAlertType, { AlertRow } from './Components/ModalListAlertType';
import { useNotifications } from '../../Notifications/NotificationContext';
import { add, set } from 'date-fns';
import Sidebar from '../../components/datagridComp/SideBar';
import ListVehiclesInAlerts from './View/ListVehiclesInAlerts';
import ListCercasInAlerts from './View/ListCercasInAlerts';




interface iModelCmd {
    onConfirm: (value?: any) => Promise<void> | void;
    message: string
    title?: string
    requireJustification: boolean
    type: typeMethods

}

const LOADING_CONTENT = 'loading'
const CONFIRMED_ACTION = 'confirm_action'
const OPEN_VEHICLE_IN_ALERT = 'OPEN_VEHICLE_IN_ALERT'
const OPEN_CERCAS_IN_ALERT = 'OPEN_CERCAS_IN_ALERT'

const optionsActions = [
    { value: 'DELALERTS', label: 'Apagar Alertas' },
    // { value: 'ACTIVE0', label: 'Desativar Alertas' },
    // { value: 'ACTIVE1', label: 'Ativar Alertas' },
];

let alertSetupSelect: itemRuleAlert | undefined;

const ConfigAlertsTab: React.FC = () => {

    const [isShowForm, setIsShowForm] = React.useState(false);
    const [ConfigAlertEdit, setConfigAlertEdit] = React.useState<itemRuleAlert>();
    const { addNotification } = useNotifications();

    //modal
    const [IsOpenSelectType, setIsOpenSelectType] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleClose = () => setIsModalOpen(false);
    const handleCloseModelCmd = () => setModelCmd(undefined);
    const [modalContent, setModalContent] = React.useState<string>('');
    const [modelCmd, setModelCmd] = React.useState<iModelCmd>();

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();



    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<itemRuleAlert[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<itemRuleAlert[]>(rows);
    const [columnsFilter, setColumnsFilter] = React.useState<Column<itemRuleAlert>[]>([]);
    const [visibleColumns, setVisibleColumns] = React.useState<string[]>(columnsFilter.map(column => column.key.toString()));


    const columns: Column<itemRuleAlert>[] = [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 148,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox>
                        <BtnCheckBox onClick={() => deleteAlert(props.row)}>🗑 Apagar</BtnCheckBox>
                    </>
                )
            }
        },
        {
            key: 'info',
            name: 'info',
            width: 130,
            renderCell(props: any) {
                return (
                    <div style={{
                        display: 'flex', alignItems: 'center', gap: '6px'
                    }}>
                        {/* <InfoBox title='Smartphones Registrados' onClick={() => handleClickRow(props.row)} >
                            <FontAwesomeIcon icon={faBell} /> {props.row.num_subscribes}
                        </InfoBox > */}
                        <InfoBox title='Veículos Registrados' onClick={() => handleOpenListVehicles(props.row)}>
                            <FontAwesomeIcon icon={faCar} /> {props.row.num_vehicles}
                        </InfoBox>
                        {props.row.tipo === 'CERCA' && <InfoBox title='Cercas Registrados' onClick={() => handleOpenListCercas(props.row)}>
                            <FontAwesomeIcon icon={faFlag} /> {props.row.num_cercas}
                        </InfoBox>}
                    </div >
                )
            }
        },
        {
            key: 'points',
            name: 'Pontos',
            width: 65,
            renderCell(props: any) {
                return (
                    props.row.points ? <div style={{
                        display: 'flex', alignItems: 'center', gap: '6px'
                    }}>
                        <InfoBox title='Pontos do Motoristas' onClick={() => handleClickRow(props.row)} >
                            ⛔ {props.row.points}
                        </InfoBox >
                    </div > : <></>
                )
            }
        },
        {
            key: 'priority',
            name: 'Nível',
            width: 90,
            renderCell(props: any) {
                return (
                    <PriorityItem level={props.row.priority}>{['', '🟢 Baixa', '🟣 Média', '🔴 Alta'][props.row.priority]}</PriorityItem>
                )
            },
        },
        // {
        //     key: 'title',
        //     name: 'Título',
        //     width: 150
        // },
        // {
        //     key: 'icon',
        //     name: 'Ícone',
        //     width: 100,
        //     // Para renderizar o ícone, você pode precisar de um renderizador customizado
        // },
        // {
        //     key: 'type_alert',
        //     name: 'Tipo de Alerta',
        //     width: 120
        // },
        {
            key: 'descr',
            name: 'Descrição',
            width: 200
        },
        // {
        //     key: 'tipo',
        //     name: 'Tipo',
        //     width: 100
        // },
        {
            key: 'param1',
            name: 'Parâmetro 1',
            width: 150,
            renderCell(props: any) {
                return (<>
                    {(props.row.tipo === 'VEL' || props.row.tipo === 'SPEED') &&
                        <InfoBox title='Velocidade' >
                            <FontAwesomeIcon icon={faTachometerAlt} /> {props.row.param1} km/h
                        </InfoBox >
                    }
                    {(props.row.tipo === 'CERCA') &&
                        <InfoBox title='Cercas' >
                            {['', 'Entrada e Saída', 'Saída da Cerca', 'Entrada na Cerca'][props.row.param1]}
                        </InfoBox >
                    }
                    {(props.row.tipo === 'STOPMOVE') &&
                        <InfoBox title='Tempo Parado' >
                            <FontAwesomeIcon icon={faStop} /> {props.row.param1} Minutos
                        </InfoBox >
                    }

                </>)
            },
        },
        {
            key: 'valor',
            name: 'Parametro 2',
            width: 150,
            renderCell(props: any) {
                return (<>
                    {(props.row.tipo === 'CERCA' && props.row.valor > 0) &&
                        <InfoBox title='Cercas' >
                            {props.row.valor} min
                        </InfoBox >
                    }

                </>)
            },
        },

        // {
        //     key: 'IsRangerWork',
        //     name: 'Ranger Ativo',
        //     width: 120
        // },
        // {
        //     key: 'semana',
        //     name: 'Semana',
        //     width: 200
        // },

        // {
        //     key: 'evento',
        //     name: 'Evento',
        //     width: 100
        // },
        // {
        //     key: 'inFavActive',
        //     name: 'Favorito Ativo (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavAlert',
        //     name: 'Alerta Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavTimer',
        //     name: 'Timer Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'inFavTol',
        //     name: 'Tolerância Favorito (In)',
        //     width: 150
        // },
        // {
        //     key: 'outFavActive',
        //     name: 'Favorito Ativo (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavAlert',
        //     name: 'Alerta Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavTimer',
        //     name: 'Timer Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'outFavTol',
        //     name: 'Tolerância Favorito (Out)',
        //     width: 150
        // },
        // {
        //     key: 'create_at',
        //     name: 'Criado Em',
        //     width: 150
        //     // Pode necessitar de um renderizador para formatar a data
        // }

    ];


    const editItem = (item: itemRuleAlert) => {
        setConfigAlertEdit(item)
        setIsShowForm(true)
    }

    const handleSearch = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleEdit = (row: itemRuleAlert) => {
        // console.log(row)
    }
    const handleClickRow = (row: itemRuleAlert) => {
        // console.log(row)
    }
    const handleOpenListVehicles = (row: itemRuleAlert) => {
        setConfigAlertEdit(row)
        setSideContent(OPEN_VEHICLE_IN_ALERT)
    }
    const handleOpenListCercas = (row: itemRuleAlert) => {
        setConfigAlertEdit(row)
        setSideContent(OPEN_CERCAS_IN_ALERT)
    }

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const deleteAlert = async (item: itemRuleAlert) => {
        alertSetupSelect = item;
        setIsModalOpen(true)
        setModalContent("CONFIRM_REMOVE_EQUIP")
    }

    const handleSelectAction = (action: string) => {
        if (!selectedRows.size) {
            addNotification('', 'Selecione ao menos um alerta para realizar a ação', 'error')
            return
        }
        switch (action) {
            case 'ACTIVE1':
                setIsModalOpen(true)
                setModalContent("CONFIRM_REMOVE_EQUIP")
                break
            case 'ACTIVE0':
                setIsModalOpen(true)
                setModalContent("PROVIDERS_CONTENT")
                break
            case 'DELALERTS':
                setIsModalOpen(true)
                setModalContent("DELALERTS")
                break
        }
    };

    async function getDados() {
        try {
            const result = await getRulesAlerts();
            console.log(result)
            setRows(result)
            setFilteredRows(result);
        } catch (e) {
            let error = e as Error
            console.log('[ListTasksDelivery]', error)
        }
    }

    React.useEffect(() => {
        getDados()
    }, [])

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((alert) => alert.id));
        setSelectedRows(allIds);
    };

    const deselectAll = () => {
        setSelectedRows(new Set());
    };

    const handleSelectAlert = async (alert: AlertRow) => {
        let payload = {
            descr: alert.descr,
            alerta_id: alert.id,
            typeAlert: alert.type_alert
        }
        await RegisterAlertsInServer(payload)
        getDados()
    };


    const handleConfirmeDelete = async (value: any) => {
        try {
            let payload = {
                alerta_id: alertSetupSelect?.id
            }
            const result = await DeleteAlert(payload);
            addNotification('', 'Alerta apagado com sucesso', 'success');
            getDados()
            setIsModalOpen(false)
        } catch (e) {
            let error = e as Error
            console.log('[DeleteAlert]', error)
        }


    };

    const handleUpdateList = () => {
        setSideContent(undefined)
        setConfigAlertEdit(undefined)
        getDados()
    }
    const CancelModal = () => {
        setSideContent(undefined)
        setConfigAlertEdit(undefined)
    }


    return (
        <>
            {!isShowForm &&
                <>
                    <SearchAndSelectContainer>
                        <SearchField onSearch={handleSearch} />
                        {/* <ActionSelect
                                    options={optionsActions}
                                    onSelect={handleSelectAction}
                                    title='Ações'
                                /> */}
                        <ColumnSelect
                            columns={columns}
                            onColumnVisibilityChange={(visibleColumnKeys) => {
                                setVisibleColumns(visibleColumnKeys);
                            }}
                        />
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getDados} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon title='Cadastrar nova regra' onClick={() => setIsOpenSelectType(true)} >
                            <BoxIcon icon={faAdd} iconColor='green' /> Criar Alerta
                        </BtnFaIcon >


                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                        <BtnCheckBox onClick={deselectAll}> ❌ Desmarcar Tudo</BtnCheckBox>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                    </ContainerInfo>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            visibleColumns={visibleColumns}
                            onRowClick={handleClickRow}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>}
            {isModalOpen && (
                <ModalComp title="" subtitle="" onClose={handleClose}>
                    {modalContent === LOADING_CONTENT && <LoadingIndicator />}
                    {modalContent === CONFIRMED_ACTION && <ConfirmationDialog type='CONFIRMED' message="Operação realizada com sucesso!" onConfirm={() => handleClose()} onCancel={() => handleClose()} />}
                </ModalComp>
            )}
            {isShowForm && <FormAlertsCreate initialData={ConfigAlertEdit} closeForm={() => {
                setIsShowForm(false)
                getDados()
            }
            } />}
            {<ModalListAlertType
                onRequestClose={() => { setIsOpenSelectType(false) }}
                isOpen={IsOpenSelectType}
                onSelectAlert={handleSelectAlert} />}
            {isModalOpen && <ModalComp title="Confirmar Ação" subtitle="" onClose={() => setIsModalOpen(false)}>
                <ConfirmationDialog
                    type={'QUESTION'}
                    onCancel={() => setIsModalOpen(false)}
                    onConfirm={handleConfirmeDelete}
                    message={`Confirme que deseja apagar o alerta ${alertSetupSelect?.descr}`} />
            </ModalComp>}
            {ConfigAlertEdit && <Sidebar width='800px' isOpen={sideContent === OPEN_VEHICLE_IN_ALERT} onClose={CancelModal} title={'Lista de Veículos'}>
                <ListVehiclesInAlerts idAlert={ConfigAlertEdit?.id ?? 0} onUpdated={handleUpdateList} />
            </Sidebar>}
            {ConfigAlertEdit && <Sidebar width='800px' isOpen={sideContent === OPEN_CERCAS_IN_ALERT} onClose={CancelModal} title={'Lista de Cercas'}>
                <ListCercasInAlerts idAlert={ConfigAlertEdit?.id ?? 0} onUpdated={handleUpdateList} />
            </Sidebar>}
        </>
    )

}
export default ConfigAlertsTab