import React from 'react';
import styled from 'styled-components';
import { useConfig } from '../../../Config/configContext';
import { MapConfig } from '../utilsMap/mapContext';

interface VehicleIconMapProps {
  driverAvatar?: string;
  vehicleImage?: string;
  vehicleIcon: string;
  color?: string;
  iconType: number;
  onClick?: () => void;
}

const Container = styled.div<{ iconType: number }>`
  position: relative;
  width: 46px;
  height: 46px;
  background-color: ${({ iconType }) => (iconType === 1 ? 'white' : 'transparent')};
  cursor: pointer;
`;

const Image = styled.img<{ isShowIcon: boolean }>`
  position: absolute;
  top: ${({ isShowIcon }) => (isShowIcon ? '50%' : '0%')};
  left: ${({ isShowIcon }) => (isShowIcon ? '50%' : '0%')};
  border-radius: ${({ isShowIcon }) => (isShowIcon ? '0%' : '50%')};
  transform: translate(-50%, -50%)
    ${({ isShowIcon }) => (isShowIcon ? 'rotate(45deg)' : '0deg')};
  width: ${({ isShowIcon }) => (isShowIcon ? 'fit-content' : '100%')};
  height: ${({ isShowIcon }) => (isShowIcon ? '80%' : '100%')};
`;

const VehicleIconMap: React.FC<VehicleIconMapProps> = ({
  driverAvatar,
  vehicleIcon,
  vehicleImage,
  color = 'gray',
  iconType,
  onClick,
}) => {
  const { showPhotoDriverInMap } = MapConfig();
  const isShowDriverAvatar = showPhotoDriverInMap && driverAvatar;
  const driverAvatarPath = driverAvatar
    ? `${process.env.REACT_APP_FILES_SERVER}/avatar-driver/${driverAvatar}`
    : null;
  const vehicleImagePath =
    !isShowDriverAvatar && vehicleImage
      ? `${process.env.REACT_APP_FILES_SERVER}/vehicle-photo/${vehicleImage}`
      : null;
  const iconPath =
    iconType === 1
      ? `assets/icons_rotate/${vehicleIcon}.png`
      : `assets/icons_maps/${vehicleIcon}.png`;

  // Verifica se deve aplicar largura proporcional
  const isShowIcon = iconType === 1 && !driverAvatarPath && !vehicleImagePath;

  return (
    <Container iconType={iconType} onClick={onClick}>
      <Image
        src={vehicleImagePath || driverAvatarPath || iconPath}
        isShowIcon={isShowIcon}
      />
    </Container>
  );
};

export default VehicleIconMap;
