
import { Column, SelectColumn } from 'react-data-grid';
import { itemChips } from '../../../interfaces/types';
import ButtonEdit from '../../../components/datagridComp/ButtonEdit';
import ChipStatusContract from '../Components/ChipStatusContract';
import StatusChip from '../Components/ChipStatus';
import ChipStatusEquip from '../Components/ChipStatusEquip';
import SmallButton from '../../../components/datagridComp/SmallButton';

export const getColumnsChips = (
    handleEditChip: (row: itemChips) => void,
    handleSelectEquip: (row: itemChips) => void
): Column<itemChips>[] => {
    return [
        {
            ...SelectColumn,
            width: 150,
        },
        {
            key: 'id',
            name: 'Cód',
            width: 80
        },
        {
            key: 'edit',
            name: 'Editar',
            width: 60,
            renderCell(props: any) {
                return (

                    <ButtonEdit onClick={() => handleEditChip(props.row)} />

                )
            }
        },
        {
            key: 'contract_status',
            name: 'Contrato',
            width: 110,
            renderCell: (props: any) => (
                <ChipStatusContract status={props.row.contract_status} />
            )
        },
        {
            key: 'status_conn',
            name: 'Status',
            width: 100,
            renderCell: (props: any) => (
                <StatusChip status={props.row.status_conn} />
            )
        },
        {
            key: 'equip_status',
            name: 'Equip',
            width: 100,
            renderCell: (props: any) => (
                <ChipStatusEquip status={props.row.equip_status} />
            )
        },
        {
            key: 'fornecedor',
            name: 'Fornecedor',
            width: 150,
        },
        {
            key: 'serial',
            name: 'Serial',
            width: 230,
        },
        {
            key: 'linha',
            name: 'Linha',
            width: 150
        },

        {
            key: 'nDiasConn',
            name: 'Conectado',
            width: 100
        },
        {
            key: 'equip_imei',
            name: 'Imei',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.equip_imei ? <>
                        <div><i>{props.row.equip_imei}</i> / <strong>{props.row.fab_nome}-{props.row.mod_ref}</strong></div>
                    </> : <SmallButton label='Selecionar Equipamento' onClick={() => handleSelectEquip(props.row)} />
                )
            }
        },
        {
            key: 'finance_status',
            name: 'Financeiro',
            width: 100
        },

        {
            key: 'cliente_name',
            name: 'Cliente',
            width: 240
        },
        {
            key: 'veiculo_placa',
            name: 'Veículo',
            width: 130
        },

        /* {
             key: 'equip_imei',
             name: 'Imei',
             width: 130
         },
         {
             key: 'fab_nome',
             name: 'Fabricante',
             width: 130
         },
         {
             key: 'mod_ref',
             name: 'Modelo',
             width: 100
         },*/


    ];

}