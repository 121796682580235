import React from 'react';
import styled from 'styled-components';

// Definição dos estilos do container dos cards
const CardsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 5px;
  gap: 10px;
  margin-bottom: 10px;
 
`;

// Estilo de cada card individual
const Card = styled.div<{ isSelected: boolean }>`
  min-width: 154px;
  border: 1px solid #ccc;
  border-top: ${(props) => (props.isSelected ? '6px solid green' : '1px solid #ccc')};
  padding: 10px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// Estilo para o título da categoria com destaque
const CategoryTitle = styled.div`
  font-size: 1.0em;
  font-weight: bold;
  color: #d35400; // Cor verde para valores monetários
`;

// Estilo para destacar o valor monetário
const Amount = styled.p`
  font-size: 1.6em;
  font-weight: bold;
  color: #27ae60; 
`;

// Estilo para o ícone SVG
const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  color: #d35400;
`;

export interface iChipsResume {
  category: string;
  category_icon: string;
  total_amount: number;
  isSelected: boolean;
  filterKey: string;
}



interface Props {
  categories: iChipsResume[];
  onClick: (filter: string) => void;
}

const ChipCardList: React.FC<Props> = ({ categories, onClick }) => {

  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <CardsContainer>
      {categories.map((category, index) => (
        <Card key={index} isSelected={category.isSelected} onClick={() => onClick(category.filterKey)}>
          {/*<Icon src={`${process.env.REACT_APP_ICON_PATH2}/icons_category/${category.category_icon}`} alt={category.category} />*/}
          <div >
            <CategoryTitle>{category.category}</CategoryTitle>
            <Amount>{category.total_amount}</Amount>
          </div>
        </Card>
      ))}
    </CardsContainer>
  );
};

export default ChipCardList;
