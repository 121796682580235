import { Column, SelectColumn } from 'react-data-grid';
import { iContractType } from '../repository/SettingsRepo';
import SmallButton from '../../../components/datagridComp/SmallButton';

export const getColumnsContracts = (
    openContract: (id: string) => void,
): Column<iContractType>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar',
        },
        // Coluna para o ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        {
            key: 'pdf',
            name: 'Abrir',
            width: 80,
            renderCell(props: any) {
                return (
                    <SmallButton label='Abrir' onClick={() => openContract(props.row.contract_id)} />
                )
            }
        },
        // Coluna para o Nome do Cliente
        {
            key: 'client_name',
            name: 'Nome do Cliente',
            width: 280,
            resizable: true,
        },
        // Coluna para o Status do Contrato
        {
            key: 'status_contract',
            name: 'Status',
            width: 150,
            resizable: true,
        },
        // Coluna para a Data de Criação do Contrato
        {
            key: 'created_at',
            name: 'Criado em',
            width: 180,
            resizable: true,
        },
        // Coluna para a Data de Assinatura do Contrato
        {
            key: 'signed_at',
            name: 'Assinado em',
            width: 180,
            resizable: true,

        },
    ];

};


export interface iClients {
    id: number;
    name: string;
    doc_number: string;
    phone1: string;
};