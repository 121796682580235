import { Column, SelectColumn } from 'react-data-grid';
import { iContractType } from '../repository/SettingsRepo';

export const getColumnsContracts = (): Column<iContractType>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar',
        },
        // Coluna para o ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para o Nome do Cliente
        {
            key: 'client_name',
            name: 'Nome do Cliente',
            width: 280,
            resizable: true,
        },
        // Coluna para o Status do Contrato
        {
            key: 'status_contract',
            name: 'Status',
            width: 150,
            resizable: true,
        },
        // Coluna para a Data de Criação do Contrato
        {
            key: 'created_at',
            name: 'Criado em',
            width: 180,
            resizable: true,
        },
        // Coluna para a Data de Assinatura do Contrato
        {
            key: 'signed_at',
            name: 'Assinado em',
            width: 180,
            resizable: true,

        },
        // Coluna para Latitude (caso seja relevante)
        {
            key: 'latitude',
            name: 'Latitude',
            width: 150,
            resizable: true,
        },
        // Coluna para Longitude (caso seja relevante)
        {
            key: 'longitude',
            name: 'Longitude',
            width: 150,
            resizable: true,
        },
    ];

};


export interface iClients {
    id: number;
    name: string;
    doc_number: string;
    phone1: string;
};