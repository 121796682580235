import { Column, SelectColumn } from 'react-data-grid';
import { iVehiclesListExcel } from '../Repository/ClientesRepo';
import { BtnCheckBox } from '../../StylePages';
import ColumnStatus from '../../../components/datagridComp/ColumnStatus';

export const getColumnsVehiclesExcel = (
    editItem: (row: iVehiclesListExcel) => void
): Column<iVehiclesListExcel>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para ações
        {
            key: 'status',
            name: 'Status',
            width: 200,
            renderCell(props: any) {
                return <ColumnStatus
                    messageLabel={props.row.status}
                    messageType={
                        props.row.status.includes('Erro') ? 'ERROR' :
                            props.row.status.includes('Salvo') ? 'SUCCESS' : ''}
                />;
            },
        },
        {
            key: 'plate_number',
            name: 'Placa',
            width: 100,
        },
        {
            key: 'vehicle_brand',
            name: 'Marca',
            width: 200,
        },
        {
            key: 'vehicle_model',
            name: 'Modelo',
            width: 200,
        },
        {
            key: 'vehicle_year',
            name: 'Ano',
            width: 100,
        },
        {
            key: 'imei',
            name: 'IMEI',
            width: 150,
        },
        {
            key: 'iddisp',
            name: 'Equip Id',
            width: 90,
        },
        {
            key: 'idcliente',
            name: 'Cód. Cliente',
            width: 95,
        },
        {
            key: 'login',
            name: 'Login',
            width: 300,
        },

    ];
};

